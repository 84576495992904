import Header from "../Header";
import styles from "./NeedInvite.module.scss";

export default function NeedInvite() {
  return (
    <div>
      <Header dumb />

      <div className={styles.infoPanel}>
        Thank you for creating an account with Elite Compensation.
        Unfortunately, this is an{" "}
        <span className={styles.emphasis}>invite-only community.</span> If you
        are interested in joining, claim an{" "}
        <span className={styles.emphasis}>invite link from a member</span>.
        <br />
        <br />
        We look forward to seeing you soon.
      </div>
    </div>
  );
}
