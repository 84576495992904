export const getOrdinal = (number: number) => {
    const ordinalRules = new Intl.PluralRules("en", {
        type: "ordinal"
    });
    const suffixes = {
        one: "st",
        two: "nd",
        few: "rd",
        other: "th",
        zero: "th",
        many: "th",
    };
    const suffix = suffixes[ordinalRules.select(number)];
    return (number + suffix);
}