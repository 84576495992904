import { useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import useUserClaimData from "../../../../hooks/UseUserClaimData";
import { Claim } from "../../../../types/claims";
import styles from "./CopyInviteCode.module.scss";

export default function CopyInviteCode() {
  const { data: claimData, status: claimDataStatus } = useUserClaimData();
  const ref = useRef(null);

  return (
    <>
      {claimDataStatus === "success" && (
        <div className={styles.copyInviteWrapper}>
          <input
            value={`${window.location.origin}/#/join?uic=${
              (claimData as Claim).claimUIC
            }`}
            className={styles.copyInviteInput}
            readOnly
            ref={ref}
          />

          <button
            className={styles.copyInviteButton}
            onClick={() => {
              toast("Invite link copied!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                pauseOnHover: true,
                progress: undefined,
                type: "success",
                theme: "dark",
              });
              ref.current.select();
              document.execCommand("copy");
              ref.current.blur();
            }}
          >
            <img
              src="/assets/images/copy.svg"
              className={styles.copyInviteIcon}
            />
            COPY YOUR INVITE LINK
          </button>
        </div>
      )}
    </>
  );
}
