import {
    collection,
    doc,
    DocumentData,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { nanoid } from "nanoid";
import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useUser,
} from "reactfire";
import { MessageType } from "../types/chat";
import { Opinion, OpinionCore, OPINION_STATE } from "../types/opinions";
import usePublicCoreData from "./UsePublicCoreData";
import useUserCoreData from "./UseUserCoreData";

export default function useOpinions({
    membershipID,
}: {
    membershipID: string;
}): {
    usersOpinion: OpinionCore;
    status: "loading" | "error" | "success";
    generalOpinion: {
        validity: {
            positive: number;
            negative: number;
        };
        compensation: {
            positive: number;
            negative: number;
        };
    };
    personalCompensationOpinion: {
        positive: number;
        negative: number;
    };
    declareOpinion: ({ topic, opinion }: { topic: 'compensation' | 'validity', opinion: OPINION_STATE }) => void;
} {
    const DB = useFirestore();

    const { status: userCoreStatus, data: userCoreData } = useUserCoreData();
    const allOpinions = collection(DB, "opinions");
    const { status: publicCoreDataStatus, data: publicCoreData } = usePublicCoreData({ membershipID });

    const allOpinionsQuery = query(
        allOpinions,
        where("receiverMembershipId", "==", membershipID)
    );

    let { status, data } = useFirestoreCollectionData(allOpinionsQuery);

    if (status !== "success" || userCoreStatus !== "success" || publicCoreDataStatus !== 'success') {
        return {
            declareOpinion: () => null,
            usersOpinion: {
                opinionOnValidity: OPINION_STATE.NONE,
                opinionOnCompensation: OPINION_STATE.NONE,
            },
            generalOpinion: {
                validity: {
                    positive: 100,
                    negative: 0,
                },
                compensation: {
                    positive: 50,
                    negative: 50,
                },
            },
            personalCompensationOpinion: {
                positive: 5,
                negative: 5,
            },
            status: "loading",
        };
    }

    const dataWithFallback = (data as Opinion[]) || [];
    const usersOpinion = dataWithFallback.find(
        (opinion) => opinion.ownerId === userCoreData.ownerId
    ) || {
        opinionOnValidity: OPINION_STATE.NONE,
        opinionOnCompensation: OPINION_STATE.NONE,
    };

    const allValidityPositiveCount = dataWithFallback.filter(
        (opinion) => opinion.opinionOnValidity === OPINION_STATE.POSITIVE
    ).length;
    const allValidityNegativeCount = dataWithFallback.filter(
        (opinion) => opinion.opinionOnValidity === OPINION_STATE.NEGATIVE
    ).length;

    let positiveValidity = 100;
    let negativeValidity = 0;

    if (allValidityPositiveCount > 0) {
        if (allValidityNegativeCount > 0) {
            positiveValidity = (100 / (allValidityPositiveCount + allValidityNegativeCount)) * allValidityPositiveCount;
            negativeValidity = 100 - positiveValidity;
        }
    } else {
        if (allValidityNegativeCount > 0) {
            negativeValidity = 100;
            positiveValidity = 0;
        }
    }


    const allCompensationPositiveCount = dataWithFallback.filter(
        (opinion) => opinion.opinionOnCompensation === OPINION_STATE.POSITIVE
    ).length;
    const allCompensationNegativeCount = dataWithFallback.filter(
        (opinion) => opinion.opinionOnCompensation === OPINION_STATE.NEGATIVE
    ).length;

    let positiveCompensation = 0;
    let negativeCompensation = 100;

    if (allCompensationPositiveCount > 0) {
        if (allCompensationNegativeCount > 0) {
            positiveCompensation = 100 - (100 / (allCompensationPositiveCount + allCompensationNegativeCount)) * allCompensationPositiveCount;
            negativeCompensation = (100 / (allCompensationPositiveCount + allCompensationNegativeCount)) * allCompensationPositiveCount;
        }
    } else {
        if (allCompensationNegativeCount > 0) {
            negativeCompensation = 0;
            positiveCompensation = 100;
        }
    }

    const personalCompensationOpinion = {
        positive: Number(publicCoreData.perceivedCompensationFairness || 0) * 10,
        negative: 100 - (Number(publicCoreData.perceivedCompensationFairness || 0) * 10)
    };

    const declareOpinion = async ({ topic, opinion }: { topic: 'validity' | 'compensation', opinion: OPINION_STATE }) => {
        const userHasAlreadyDefinedOpinion = 'id' in usersOpinion;

        if (userHasAlreadyDefinedOpinion) {
            const existingOpinionRef = doc(DB, "opinions", usersOpinion.id);
            await updateDoc(existingOpinionRef, {
                ...(topic === 'compensation' ? {
                    opinionOnCompensation: opinion
                } : {
                    opinionOnValidity: opinion
                })
            });
        } else {
            const id = nanoid(10);

            await setDoc(doc(DB, "opinions", id), {
                id,
                timestamp: serverTimestamp(),
                ownerId: userCoreData.id,
                ownerMembershipId: userCoreData.membershipId,
                receiverId: publicCoreData.id,
                receiverMembershipId: membershipID,
                opinionOnValidity: topic === 'validity' ? opinion : OPINION_STATE.NONE,
                opinionOnCompensation: topic === 'compensation' ? opinion : OPINION_STATE.NONE
            });
        }
    }

    return {
        declareOpinion,
        usersOpinion,
        generalOpinion: {
            validity: {
                positive: Number(positiveValidity.toFixed()),
                negative: Number(negativeValidity.toFixed()),
            },
            compensation: {
                positive: Number(positiveCompensation.toFixed()),
                negative: Number(negativeCompensation.toFixed()),
            },
        },
        personalCompensationOpinion,
        status,
    };
}
