import styles from "./InvalidClaim.module.scss";

export default function InvalidClaim() {
  return (
    <div className={styles.container} style={{ height: window.outerHeight }}>
      <div className={styles.frameLeft}>
        <div className={styles.upper}>
          <img src="./assets/images/logo_2.png" className={styles.logo} />
        </div>
        <div className={styles.lower}>
          <div className={styles.invalidClaim}>
            <span className={styles.emphasis}>
              INVALID OR EXPIRED INVITE CODE
            </span>
          </div>
          <div className={styles.infoFrame}>
            <div className={styles.infoVerbiage}>
              Verify you{" "}
              <span className={styles.emphasis}>
                copied or clicked the invite link in its entirety
              </span>
              . If invite link is still invalid,{" "}
              <span className={styles.emphasis}>
                request a new invite link from a fellow attorney
              </span>
              .
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.frameRight}
        style={{ backgroundImage: 'url("./assets/images/verify.png")' }}
      ></div>
    </div>
  );
}
