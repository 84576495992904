import {
    ConfirmationResult,
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signOut,
} from "firebase/auth";
import { cleanPhoneNumber } from "./normalize";

const getAppVerifier = () => {
    const auth = getAuth();
    return new RecaptchaVerifier(
        "sign-in-button",
        {
            size: "invisible",
            callback: () => { },
        },
        auth
    );
};

export const triggerSignIn = ({
    phoneNumber,
    callback,
}: {
    phoneNumber: string;
    callback: (confirmationResult: ConfirmationResult) => void;
}) => {
    const APP_VERIFIER = getAppVerifier();
    const auth = getAuth();
    const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);

    signInWithPhoneNumber(auth, cleanedPhoneNumber, APP_VERIFIER)
        .then((confirmationResult) => {
            callback(confirmationResult);
        })
        .catch((_) => { });
};

export const confirmWithCode = ({
    success,
    failure,
    code,
    confirmationResult,
}: {
    success: (result: any) => void;
    failure: (error: any) => void;
    code: string;
    confirmationResult: ConfirmationResult;
}) => {
    confirmationResult
        .confirm(code)
        .then((result) => {
            success(result.user);
        })
        .catch((error) => {
            failure(error);
        });
};

export const logOut = (callback: () => void) => {
    const auth = getAuth();
    signOut(auth)
        .then(() => {
            callback();
        })
        .catch((_) => { });
};
