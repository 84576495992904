import millify from "millify";
import useAllCoreData from "../../../../hooks/UseAllCoreData";
import { Core } from "../../../../types/core";
import { getOrdinal } from "../../../../utils/numbers";
import styles from "./Overview.module.scss";

export default function Overview({ core }: { core: Core }) {
  let dollarUSLocale = Intl.NumberFormat("en-US");
  const { data: allCore = [] } = useAllCoreData();

  const billableHoursRank =
    allCore
      .sort((a, b) => b.billableHours - a.billableHours)
      .findIndex((a) => a.id === core.id) + 1;

  const bookOfBusinessRank =
    allCore
      .sort((a, b) => b.bookOfBusiness - a.bookOfBusiness)
      .findIndex((a) => a.id === core.id) + 1;

  const totalCompensationRank =
    allCore
      .sort((a, b) => b.salary + b.bonus - (a.salary + a.bonus))
      .findIndex((a) => a.id === core.id) + 1;

  return (
    <>
      <div className={styles.gridContainerMain}>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingRow}>
            <div className={styles.cellHeadingMain}>Billable Hours Rank</div>
            <div className={styles.cellHeadingSecondary}>
              {/* LAST UPDATED YESTERDAY */}
            </div>
          </div>
          <div className={styles.cellMainContent}>
            {getOrdinal(billableHoursRank)}
          </div>
        </div>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingRow}>
            <div className={styles.cellHeadingMain}>Book of Business Rank</div>
            <div className={styles.cellHeadingSecondary}>
              {/* LAST UPDATED YESTERDAY */}
            </div>
          </div>
          <div className={styles.cellMainContent}>
            {getOrdinal(bookOfBusinessRank)}
          </div>
        </div>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingRow}>
            <div className={styles.cellHeadingMain}>
              Total Compensation Rank
            </div>
            <div className={styles.cellHeadingSecondary}>
              {/* LAST UPDATED YESTERDAY */}
            </div>
          </div>
          <div className={styles.cellMainContent}>
            {getOrdinal(totalCompensationRank)}
          </div>
        </div>
      </div>
      <div className={styles.gridContainerSecondary}>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingSubheading}>Book of Business</div>
          <div className={styles.cellMainMinorContent}>
            ${millify(core.bookOfBusiness)}
          </div>
        </div>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingSubheading}>Working Billables</div>
          <div className={styles.cellMainMinorContent}>
            ${millify(core.hourlyRate * core.billableHours)}
          </div>
        </div>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingSubheading}>
            Collected Billables
          </div>
          <div className={styles.cellMainMinorContent}>
            ${millify(core.collectedWorkingBillables)}
          </div>
        </div>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingSubheading}>Billable Hours</div>
          <div className={styles.cellMainMinorContent}>
            {dollarUSLocale.format(core.billableHours)}
          </div>
        </div>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingSubheading}>Hourly Rate</div>
          <div className={styles.cellMainMinorContent}>
            ${dollarUSLocale.format(core.hourlyRate)}
          </div>
        </div>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingSubheading}>Salary</div>
          <div className={styles.cellMainMinorContent}>
            ${millify(core.salary)}
          </div>
        </div>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingSubheading}>Bonus</div>
          <div className={styles.cellMainMinorContent}>
            ${millify(core.bonus)}
          </div>
        </div>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingSubheading}>Total</div>
          <div className={styles.cellMainMinorContent}>
            ${millify(core.bonus + core.salary)}
          </div>
        </div>
      </div>
    </>
  );
}
