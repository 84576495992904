import clsx from "clsx";
import {
  Link,
  NavLink,
  Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useSigninCheck } from "reactfire";
import useChat from "../../../../hooks/UseChat";
import { logOut } from "../../../../utils/authentication";
import style from "./Header.module.scss";

export default function Header({ dumb = false }: { dumb?: boolean }) {
  const history = useHistory();
  const { data: signInCheckResult } = useSigninCheck();
  const { data: conversations, isLoading } = useChat();
  const { pathname } = useLocation();

  const hasUnseen = !!(conversations || []).find(
    (conversation) => conversation.hasUnseen
  );
  const onMessagesPage = pathname.includes("messages");

  return (
    <div className={style["header-wrapper"]}>
      <Link to="/">
        <img
          src="/favicon.png"
          alt="Elite Compensation Logo"
          className={clsx(style["header-logo"], "hide-on-desktop")}
        />
        <>
          <img
            src="/assets/images/logo_2.png"
            alt="Elite Compensation Logo"
            className={clsx(style["header-logo"], "hide-on-mobile")}
          />
        </>
      </Link>
      <div className={style["header-button-wrapper"]}>
        {signInCheckResult.signedIn ? (
          <>
            {!dumb && (
              <>
                <NavLink
                  to="/"
                  exact
                  className={style["header-button"]}
                  activeClassName={style.active}
                  title="Compare compensation data"
                >
                  DASHBOARD
                </NavLink>
                <NavLink
                  to="/messages"
                  className={style["header-button"]}
                  activeClassName={style.active}
                  title="Message members"
                >
                  {!onMessagesPage && hasUnseen && (
                    <div className={style.conversationHasUnseenIcon}></div>
                  )}
                  MESSAGES
                </NavLink>
                {/* <NavLink
                  to="/profile"
                  className={style["header-button"]}
                  activeClassName={style.active}
                  title="View your profile"
                >
                  PROFILE
                </NavLink> */}
              </>
            )}
            <button
              onClick={() => {
                logOut(() => {
                  localStorage.removeItem("ec_uic");
                  history.push("/login");
                });
              }}
              title="Log out of Elite Compensation"
              className={style["header-button"]}
            >
              LOG OUT
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              history.push("/login");
            }}
            title="Log in to Elite Compensation"
            className={style["header-button"]}
          >
            LOG IN
          </button>
        )}
      </div>
    </div>
  );
}
