export enum OPINION_STATE {
    POSITIVE = "positive",
    NEGATIVE = "negative",
    NONE = "none",
}

export type Opinion = OpinionCore & {
    id: string;
    ownerId: string;
    ownerMembershipId: string;
    receiverId: string;
    receiverMembershipId: string;
    timestamp: {
        seconds: number;
        nanoseconds: number;
    };
};

export type OpinionCore = {
    opinionOnValidity: OPINION_STATE;
    opinionOnCompensation: OPINION_STATE;
};
