import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useInitializeChat from "../../../../../../hooks/UseInitializeChat";
import useOpinions from "../../../../../../hooks/UseOpinions";
import { OPINION_STATE } from "../../../../../../types/opinions";
import styles from "./CompensationScoreBar.module.scss";

export default function CompensationScoreBar({
  membershipId,
  ownerId,
  isUser = false,
}: {
  membershipId: string;
  ownerId: string;
  isUser?: boolean;
}) {
  const { initialize } = useInitializeChat();
  const history = useHistory();

  const {
    status,
    usersOpinion,
    generalOpinion,
    declareOpinion,
    personalCompensationOpinion,
  } = useOpinions({
    membershipID: membershipId,
  });

  const submittedCompensationOpinion =
    usersOpinion.opinionOnCompensation !== OPINION_STATE.NONE;

  const submittedValidityOpinion =
    usersOpinion.opinionOnValidity !== OPINION_STATE.NONE;

  if (status !== "success") {
    return <></>;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.dataBarRow}>
        {personalCompensationOpinion.positive > 0 && (
          <>
            <div className={styles.barLabel}>
              THIS MEMBER'S COMPENSATION SENTIMENT
            </div>
            {submittedCompensationOpinion ? (
              <div className={clsx(styles.barWrapper, styles.personal)}>
                {personalCompensationOpinion.negative > 0 && (
                  <div
                    className={clsx(styles.belowBar, styles.personal)}
                    style={{
                      width: `${personalCompensationOpinion.negative}%`,
                    }}
                  >
                    {/* {personalCompensationOpinion.negative > 30 && (
                      <>OVERPAID ({personalCompensationOpinion.negative}%)</>
                    )} */}
                  </div>
                )}
                {personalCompensationOpinion.positive > 0 && (
                  <div className={clsx(styles.upperNumber, styles.personal)}>
                    {/* {personalCompensationOpinion.positive > 30 && (
                      <>({personalCompensationOpinion.positive}%) UNDERPAID</>
                    )} */}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.barWrapperSkeleton}>VOTE TO REVEAL</div>
            )}
          </>
        )}

        <div className={styles.barLabel}>
          COMMUNITY'S COMPENSATION SENTIMENT
        </div>
        {submittedCompensationOpinion ? (
          <div className={styles.barWrapper}>
            {generalOpinion.compensation.negative > 0 && (
              <div
                className={styles.belowBar}
                style={{ width: `${generalOpinion.compensation.negative}%` }}
              >
                {generalOpinion.compensation.negative > 30 && (
                  <>OVERPAID ({generalOpinion.compensation.negative}%)</>
                )}
              </div>
            )}
            {generalOpinion.compensation.positive > 0 && (
              <div className={styles.upperNumber}>
                {generalOpinion.compensation.positive > 30 && (
                  <>({generalOpinion.compensation.positive}%) UNDERPAID</>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.barWrapperSkeleton}>VOTE TO REVEAL</div>
        )}
        <div className={styles.actionRow}>
          <div
            onClick={() => {
              toast("Maybe a recruiting opportunity? 🤔", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                pauseOnHover: false,
                progress: undefined,
                type: "success",
                theme: "light",
              });
              declareOpinion({
                topic: "compensation",
                opinion: OPINION_STATE.NEGATIVE,
              });
            }}
            className={clsx(styles.actionButton, styles.negative, {
              [styles.active]:
                usersOpinion.opinionOnCompensation === OPINION_STATE.NEGATIVE,
            })}
          >
            UNDER PAID
          </div>
          <div
            onClick={() => {
              toast("The firm's overpaying, here. 🤭", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                pauseOnHover: false,
                progress: undefined,
                type: "success",
                theme: "light",
              });
              declareOpinion({
                topic: "compensation",
                opinion: OPINION_STATE.POSITIVE,
              });
            }}
            className={clsx(styles.actionButton, styles.positive, {
              [styles.active]:
                usersOpinion.opinionOnCompensation === OPINION_STATE.POSITIVE,
            })}
          >
            OVER PAID
          </div>
        </div>
      </div>
      <div className={styles.dataBarRow}>
        <div className={styles.barLabel}>COMMUNITY'S VALIDITY SENTIMENT</div>

        {submittedValidityOpinion ? (
          <div className={clsx(styles.barWrapper, styles.validity)}>
            {generalOpinion.validity.negative > 0 && (
              <div
                className={clsx(styles.belowBar, styles.validity)}
                style={{ width: `${generalOpinion.validity.negative}%` }}
              >
                {generalOpinion.validity.negative}%
              </div>
            )}
            {generalOpinion.validity.positive > 0 && (
              <div className={styles.upperNumber}>
                {generalOpinion.validity.positive}%
              </div>
            )}
          </div>
        ) : (
          <div className={styles.barWrapperSkeleton}>VOTE TO REVEAL</div>
        )}
        <div className={styles.actionRow}>
          <div
            onClick={() => {
              toast("Don't try to pad hours here! 🤥", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                pauseOnHover: false,
                progress: undefined,
                type: "success",
                theme: "light",
              });
              declareOpinion({
                topic: "validity",
                opinion: OPINION_STATE.NEGATIVE,
              });
            }}
            className={clsx(styles.actionButton, styles.negativeValidity, {
              [styles.active]:
                usersOpinion.opinionOnValidity === OPINION_STATE.NEGATIVE,
            })}
          >
            LOOKS WRONG
          </div>
          <div
            onClick={() => {
              toast("👍🏼 Thanks for the feedback!", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                pauseOnHover: false,
                progress: undefined,
                type: "success",
                theme: "light",
              });
              declareOpinion({
                topic: "validity",
                opinion: OPINION_STATE.POSITIVE,
              });
            }}
            className={clsx(styles.actionButton, styles.positiveValidity, {
              [styles.active]:
                usersOpinion.opinionOnValidity === OPINION_STATE.POSITIVE,
            })}
          >
            LOOKS RIGHT
          </div>
        </div>
      </div>

      <div className={styles.actionRowSecondary}>
        {/* <div
          onClick={() => {
            toast("In👍🏼 Thanks for the feedback!", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                pauseOnHover: false,
                progress: undefined,
                type: "success",
                theme: "light",
              });
            declareOpinion({
              topic: "validity",
              opinion: OPINION_STATE.POSITIVE,
            });
          }}
          className={clsx(styles.actionButtonSecondary, styles.positive, {
            [styles.active]:
              usersOpinion.opinionOnValidity === OPINION_STATE.POSITIVE,
          })}
        >
          LOOKS ACCURATE
        </div>
        <div
          onClick={() => {
            toast("In👍🏼 Thanks for the feedback!", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                pauseOnHover: false,
                progress: undefined,
                type: "success",
                theme: "light",
              });
            declareOpinion({
              topic: "validity",
              opinion: OPINION_STATE.NEGATIVE,
            });
          }}
          className={clsx(styles.actionButtonSecondary, styles.negative, {
            [styles.active]:
              usersOpinion.opinionOnValidity === OPINION_STATE.NEGATIVE,
          })}
        >
          DOESN'T MAKE SENSE
        </div> */}
        {!isUser && (
          <div
            className={clsx(styles.actionButtonSecondary, styles.message)}
            onClick={() => {
              initialize({
                receiverId: ownerId,
                receiverMembershipId: membershipId,
                onSuccess: (conversationId: string) => {
                  history.push(`/messages/${conversationId}`);
                },
              });
            }}
          >
            MESSAGE THIS MEMBER
          </div>
        )}
      </div>
    </div>
  );
}
