import { collection, doc, DocumentData, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData, useUser } from "reactfire";
import { Core } from "../types/core";

export default function usePublicCoreData({ membershipID }: { membershipID: string; }): { data: Core, status: "loading" | "error" | "success" } {
    const DB = useFirestore();

    const coreCollection = collection(DB, "core");
    const corePublicQuery = query(
        coreCollection,
        where("membershipId", "==", membershipID)
    );

    let { status, data } =
        useFirestoreCollectionData(corePublicQuery);


    return {
        data: (status !== "success" ? {} : data[0]) as Core,
        status
    }
}