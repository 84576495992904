import { Link } from "react-router-dom";
import Header from "../Application/components/Header/Header";
import style from "./LandingPage.module.scss";

export default function LandingPage() {
  return (
    <div>
      <Header />
      <div
        className={style.first}
        style={{
          backgroundImage: 'url("/assets/images/landing_page_top.png")',
        }}
      >
        <div className={style.firstVerbiage}>
          <div className={style.firstVerbiageTop}>Top attorneys.</div>
          <div className={style.firstVerbiageBottom}>
            Anonymous compensation comparison.
          </div>
        </div>
      </div>
      <div className={`${style.section}`}>
        <div className={`${style.sectionHeader} ${style.dark}`}>
          Invitation only.
        </div>
        <div className={`${style.sectionBody}`}>
          Only an invite from a fellow attorney grants access to this sensitive
          data. By limiting access, you can reliably compare data to other top
          performers without prying eyes.
          <br />
          <br />
          The world doesn't need more corporate data mining schemes. Join an
          elite community where your performance speaks for itself.
        </div>
      </div>
      <div
        className={`${style.section} ${style.dark}`}
        style={{ backgroundImage: 'url("/assets/images/hex_1.png")' }}
      >
        <div className={`${style.sectionHeader} ${style.light}`}>
          Anonymous by design.
        </div>
        <div className={`${style.sectionBody} ${style.light}`}>
          Market transparency starts with excluding any information that exposes
          you to peers. This allows honest comparisons without worry.
          <br />
          <br />
          No member can access or identify any unique information that would
          compromise anonymity. Compare against fellow attorneys in your own
          office without knowing it.
        </div>
      </div>
      <div className={`${style.section} ${style.light}`}>
        <div className={`${style.sectionHeader} ${style.dark}`}>
          Peer verification.
        </div>
        <div className={`${style.sectionBody}`}>
          Data quality is critical to accurate comparisons. Verification by
          peers allows inaccurate data to be removed or updated before shown to
          the rest of the community.
          <br />
          <br />
          Additionally, using analytics and manual reviews, the community can
          identify outliers and attorneys gaming the system.
        </div>
      </div>
      <div
        className={`${style.section} ${style.gold}`}
        style={{ backgroundImage: 'url("/assets/images/hex_2.png")' }}
      >
        <div className={`${style.sectionHeader} ${style.light}`}>
          Security first.
        </div>
        <div className={`${style.sectionBody} ${style.light}`}>
          The Elite Compensation data team cannot even see the raw data inputted
          by the community. By encrypting every data point, we ensure that a
          even a data dump would not include in any decipherable data.
          <br />
          <br />
          Regular audits, top-of-class protocols, and encryption-first design
          allows our community to confidently and transparently compare
          compensation.
        </div>
      </div>
      <div className={`${style.footer}`}>
        <div className={`${style.trademark}`}>
          {new Date().getFullYear} © Elite Compensation, LLC.
        </div>
        <div className={`${style.linksWrapper}`}>
          <a href="mailto:contact@elitecompensation.com" className={style.link}>
            CONTACT
          </a>
          <Link to="/terms" className={style.link}>
            TERMS
          </Link>
        </div>
      </div>
    </div>
  );
}
