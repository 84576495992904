import "firebase/database";
import Loader from "../../components/Loader";
import useAllAmplifiedCoreData from "../../hooks/UseAllAmplifiedCoreData";
import useUserCoreData from "../../hooks/UseUserCoreData";
import CopyInviteCode from "./components/CopyInviteCode";
import PerformanceCard from "./components/PerformanceCard";
import styles from "./Dashboard.module.scss";

const invitePhrases = [
  "KNOW SOMEONE WHO QUALIFIES FOR MEMBERSHIP?",
  "WANT TO GROW YOUR COMMUNITY?",
  "WHERE DO YOU STAND AT YOUR FIRM?",
  "KNOW SOMEONE YOU WANT TO RECRUIT?",
  "HOW DOES YOUR MARKET STACK UP?",
  "HOW DO YOU COMPARE TO YOUR NETWORK?",
];

const getRandomPhrase = () => {
  return invitePhrases[Math.floor(Math.random() * invitePhrases.length)];
};

export default function Dashboard() {
  const { status } = useUserCoreData();
  const {
    status: allAmplifiedCoreStatus,
    data: allAmplifiedCoreData,
    userData: userAmplifiedCoreData,
  } = useAllAmplifiedCoreData();

  const { status: userCoreStatus } = useUserCoreData();

  return (
    <div>
      {userCoreStatus === "success" &&
      status === "success" &&
      allAmplifiedCoreStatus === "success" ? (
        <>
          <div
            className={styles.mainContent}
            style={{ minHeight: window.outerHeight - 150 }}
          >
            {userAmplifiedCoreData && (
              <>
                <PerformanceCard coreAmplified={userAmplifiedCoreData} isUser />
                <div className={styles.userDataDivider}></div>
              </>
            )}
            {allAmplifiedCoreData.map((coreAmplifed, idx) => (
              <div key={coreAmplifed.ownerId}>
                <PerformanceCard
                  coreAmplified={coreAmplifed}
                  key={coreAmplifed.id}
                />
                {idx > 0 && idx % 9 === 0 && (
                  <div className={styles.copyInviteCodeRow}>
                    <div className={styles.copyInviteCodeVerbiage}>
                      {getRandomPhrase()}
                    </div>
                    <CopyInviteCode />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={styles.footer}>v.2.1.6</div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
