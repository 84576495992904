import { collection, DocumentData } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Core } from "../types/core";

export default function useAllCoreData(): { data: Core[], status: "loading" | "error" | "success" } {
    const allCore = collection(useFirestore(), "core");

    const { status, data } = useFirestoreCollectionData(allCore);

    return {
        data: (data as Core[]),
        status
    }
}