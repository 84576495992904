import clsx from "clsx";
import Linkify from "linkify-react";
import qs from "qs";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useUser } from "reactfire";
import useChat from "../../hooks/UseChat";
import { Conversation } from "../../types/chat";
import styles from "./Messages.module.scss";

export default function Messages() {
  const { pathname } = useLocation();
  const conversationIdFromURL = pathname
    .replace("/messages", "")
    .replace("/", "");
  const [detailVisible, setDetailVisible] = useState(true);
  const [message, setMessage] = useState("");
  const [activeConversation, setActiveConversation] = useState<Conversation>();
  const [activeConversationId, setActiveConversationId] = useState("");
  const { data: user, status } = useUser();
  const {
    data: conversations,
    isLoading,
    send: sendMessage,
    markAsSeen: markConversationAsSeen,
  } = useChat();

  const getLastMessage = (conversation: Conversation) => {
    return conversation.messages[conversation.messages.length - 1];
  };

  const ref = useRef(null);
  const activeConversationBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      (activeConversationId && activeConversationId.length > 0) ||
      (conversationIdFromURL && conversationIdFromURL.length > 0)
    ) {
      setActiveConversation(
        conversations.find(
          (conversation) =>
            conversation.conversationId ===
            (activeConversationId || conversationIdFromURL)
        )
      );
      setDetailVisible(true);
      setTimeout(() => {
        if (activeConversationBodyRef && activeConversationBodyRef.current) {
          activeConversationBodyRef.current.scrollTo({
            top: activeConversationBodyRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
        if (ref.current) {
          ref.current?.focus();
        }
      }, 300);
    } else {
      setActiveConversation(
        conversations.find(
          (conversation) => conversation.conversationId === activeConversationId
        )
      );
    }
  }, [conversations, activeConversationId]);

  return (
    <>
      {/* <ReactTooltip /> */}
      {detailVisible && activeConversation && (
        <div className={clsx(styles.detailWrapper)}>
          <div className={styles.detailHeader}>
            <img
              alt=""
              className={styles.headerBack}
              src="assets/images/back.png"
              onClick={() => {
                setDetailVisible(false);
              }}
            />
            <Link
              to={`/profile/${activeConversation.membershipId}`}
              title={`View member ${activeConversation.membershipId}'s profile`}
            >
              {activeConversation.membershipId}
            </Link>
          </div>

          {activeConversation.messages.length > 0 ? (
            <div
              className={styles.body}
              ref={activeConversationBodyRef}
              style={{ height: window.innerHeight - 50 }}
            >
              <>
                {activeConversation.messages.map((message) => (
                  <div className={styles.messageWrapper} key={message.id}>
                    <div
                      className={clsx(styles.messageBubble, {
                        [styles.userAuthored]: message.senderId === user.uid,
                        [styles.otherUserAuthored]:
                          message.senderId !== user.uid,
                      })}
                    >
                      <Linkify options={{ target: "_blank" }}>
                        {message.body}
                      </Linkify>
                    </div>
                  </div>
                ))}
              </>
            </div>
          ) : (
            <div className={styles.chatDetailEmptyState}>
              <div className={styles.primary}>
                {" "}
                No messages with this member
              </div>
              {/* <div className={styles.secondary}>Chat to </div> */}
            </div>
          )}
          <input
            className={styles.messageInput}
            value={message}
            ref={ref}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if (message.length > 0) {
                  sendMessage({
                    message,
                    conversationId: activeConversation.conversationId,
                    receiverId: activeConversation.otherMemberId,
                    receiverMembershipId: activeConversation.membershipId,
                  });
                  setMessage("");
                  setTimeout(() => {
                    activeConversationBodyRef.current.scrollTo({
                      top: activeConversationBodyRef.current.scrollHeight,
                      // behavior: "smooth",
                    });
                  }, 200);
                }
              }
            }}
            placeholder="Say something ..."
          />
          <div
            className={styles.messageSendButton}
            onClick={() => {
              sendMessage({
                message,
                conversationId: activeConversation.conversationId,
                receiverId: activeConversation.otherMemberId,
                receiverMembershipId: activeConversation.membershipId,
              });

              setMessage("");
              setTimeout(() => {
                activeConversationBodyRef.current.scrollTo({
                  top: activeConversationBodyRef.current.scrollHeight,
                  // behavior: "smooth",
                });
              }, 200);
            }}
          >
            <img
              alt=""
              src="assets/images/right-arrow.png"
              className={styles.messageSendButtonIcon}
            />
          </div>
        </div>
      )}

      {(!detailVisible || !activeConversation) && (
        <div className={clsx(styles.wrapper)}>
          <div className={styles.body}>
            {isLoading ? (
              <>Loading ...</>
            ) : (
              <>
                {conversations.map((conversation) => (
                  <div
                    key={conversation.conversationId}
                    className={styles.conversation}
                    onClick={() => {
                      setActiveConversationId(conversation.conversationId);
                      setDetailVisible(true);
                      setTimeout(() => {
                        if (ref.current) {
                          ref.current?.focus();
                        }

                        if (
                          activeConversationBodyRef &&
                          activeConversationBodyRef.current
                        ) {
                          activeConversationBodyRef.current.scrollTo({
                            top: activeConversationBodyRef.current.scrollHeight,
                            // behavior: "smooth",
                          });
                        }
                        markConversationAsSeen({
                          conversationId: conversation.conversationId,
                        });
                      }, 500);
                    }}
                  >
                    <div className={styles.conversationMemberInfo}>
                      {conversation.membershipId}
                      {conversation.hasUnseen && (
                        <div className={styles.conversationHasUnseenIcon}></div>
                      )}
                    </div>
                    <div className={styles.conversationPreview}>
                      {conversation.messages.length > 0 ? (
                        <>
                          {getLastMessage(conversation).senderId ===
                          user.uid ? (
                            <b>YOU:</b>
                          ) : (
                            <b>THEM:</b>
                          )}{" "}
                          {getLastMessage(conversation).body}
                        </>
                      ) : (
                        <i>No messages sent, yet</i>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
