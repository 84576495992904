import {
    collection,
    doc,
    DocumentData,
    getDocs,
    Query,
    query,
    updateDoc,
    setDoc,
    where,
} from "firebase/firestore";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { Core } from "../types/core";
import { VerificationRequest, VerificationRequestStatus } from "../types/verificationRequests";
import useAllCoreData from "./UseAllCoreData";
import useUserClaimData from "./UseUserClaimData";

const getAllDocs = async (
    verifierQuery: Query<DocumentData>,
    allCoreData: Core[],
    callback: (data: any[]) => void
) => {
    if (allCoreData && allCoreData.length > 0) {

        const querySnapshot = await getDocs(verifierQuery);
        const hydratedData = <Core[] & VerificationRequest[]>[];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const thisCoreData = allCoreData.find(data => data.ownerId === doc.data().requester);
            hydratedData.push({ ...thisCoreData, ...doc.data() });
        });

        callback(hydratedData);
    }
};

export default function useUserVerificationRequest(): {
    data: {
        requesterData: DocumentData;
        verifierData: DocumentData;
        verifierCoreData: Core[] & VerificationRequest[];
    };
    status: {
        verifierStatus: "loading" | "error" | "success";
        requesterStatus: "loading" | "error" | "success";
    };
    requestVerification: () => void;
    verifyUser: (config: { userUID: string; requestID: string; }) => void;
    unverifyUser: (config: { requestID: string; }) => void;
} {
    const [verifierCoreData, setVerifierCoreData] = useState<Core[] & VerificationRequest[]>();

    const { data: user } = useUser();
    const db = useFirestore();
    const { data: allCore } = useAllCoreData();

    const { status: claimStatus, data: claim } = useUserClaimData();

    const verificationRequests = collection(db, "verificationRequests");
    const claims = collection(db, "claims");

    const invitersClaim =
        claimStatus === "success" ? claim.claimUICCreatedWith : "";
    const inviterQuery = query(claims, where("claimUIC", "==", invitersClaim));

    const requesterQuery = query(
        verificationRequests,
        where("requester", "==", user.uid)
    );
    const verifierQuery = query(
        verificationRequests,
        where("verifier", "==", user.uid)
    );

    const { status: inviterStatus, data: inviterData } =
        useFirestoreCollectionData(inviterQuery);

    const { status: requesterStatus, data: requesterData } =
        useFirestoreCollectionData(requesterQuery);

    const { status: verifierStatus, data: verifierData } =
        useFirestoreCollectionData(verifierQuery);

    useEffect(() => {

        getAllDocs(verifierQuery, allCore as Core[], (allData: Core[] & VerificationRequest[]) => {
            setVerifierCoreData(allData);
        });
    }, []);

    const requestVerification = () => {
        const id = nanoid();

        setDoc(doc(db, "verificationRequests", id), {
            id,
            requester: user.uid,
            verifier: inviterData[0].ownerId,
            requesterPhone: user.phoneNumber,
            invitersClaim,
            verifiersClaim: inviterData[0].claimUICCreatedWith,
            status: VerificationRequestStatus.PENDING
        });
    };


    const verifyUser = async ({ userUID, requestID }: { userUID: string; requestID: string; }) => {

        const userCoreRef = doc(db, "core", userUID);
        const verificationRequestRef = doc(db, "verificationRequests", requestID);

        await updateDoc(userCoreRef, {
            verified: true
        });

        await updateDoc(verificationRequestRef, {
            status: VerificationRequestStatus.VERIFIED
        });
    }

    const unverifyUser = async ({ requestID }: { requestID: string; }) => {
        const verificationRequestRef = doc(db, "verificationRequests", requestID);

        await updateDoc(verificationRequestRef, {
            status: VerificationRequestStatus.INVALID
        });
    }


    return {
        data: {
            requesterData: (requesterData || []).filter((request) => request.status !== VerificationRequestStatus.VERIFIED),
            verifierData,
            verifierCoreData,
        },
        status: { requesterStatus, verifierStatus },
        requestVerification,
        verifyUser,
        unverifyUser
    };
}
