import { useLocation } from "react-router-dom";
import qs from "qs";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import Loader from "../../components/Loader";
import { useState } from "react";
import { useEffect } from "react";
import VerifyEligibility from "./components/VerifyEligibility";
import InavlidClaim from "./components/InavlidClaim";
import RegisterNumber from "./components/RegisterNumber";
import NotQualified from "./components/NotQualified";

export default function Join() {
  const { search } = useLocation();
  const queryObject = qs.parse(search.replace("?", ""));
  const [claimIsValid, setClaimIsValid] = useState(false);
  const [verifyValidity, setVerifiyValidity] = useState(false);
  const [showNotQualified, setShowNotQualified] = useState(false);

  const UIC = "uic" in queryObject ? queryObject.uic : "";
  const claims = collection(useFirestore(), "claims");
  const coreQuery = query(claims, where("claimUIC", "==", UIC));
  const { status, data } = useFirestoreCollectionData(coreQuery);

  useEffect(() => {
    if (UIC.length > 0 && status === "success" && data.length > 0) {
      {
        setClaimIsValid(true);
      }
    } else {
      setClaimIsValid(false);
    }
  }, [status, data]);

  return (
    <>
      {status === "loading" ? (
        <Loader />
      ) : (
        <>
          {claimIsValid ? (
            <>
              {verifyValidity ? (
                <RegisterNumber uic={UIC as string} />
              ) : showNotQualified ? (
                <NotQualified />
              ) : (
                <VerifyEligibility
                  onClickVerify={() => {
                    setVerifiyValidity(true);
                  }}
                  onClickNotQualified={() => {
                    setShowNotQualified(true);
                  }}
                />
              )}
            </>
          ) : (
            <InavlidClaim />
          )}
        </>
      )}
    </>
  );
}
