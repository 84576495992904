import clsx from "clsx";
import styles from "./core.module.scss";
import { Core, CoreAmplified, NumericCoreKey } from "../../types/core";
import { getOrdinal } from "../numbers";

export const valueComparisonClass = (
    cellValue: number,
) => {
    return clsx({
        [styles.higher]: cellValue > 0,
        [styles.lower]: cellValue < 0,
    });
};

export const sortCoreByKey = ({ allCore, key }: { allCore: Core[], key: NumericCoreKey }) => {
    return [...allCore].sort((a, b) => (b[key] - a[key]));
}

export const getRankFromSortedCore = ({ sortedCore, currentCore }: { sortedCore: Core[]; currentCore: Core; }) => {
    return getOrdinal(sortedCore.findIndex((a) => a.id === currentCore.id) + 1);
}