import { useEffect, useState } from "react";
import "./Loader.scss";

const actionTexts = [
  "Initializing secure connection ...",
  "Decrypting secure data ...",
  "Distributing queing integration ...",
  "Configuring connection sequence ...",
  "Ranking compensation ...",
];

export default function Loader() {
  const [verbiageIdx, setVerbiageIdx] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setVerbiageIdx((curr) =>
        curr === actionTexts.length - 1 ? 0 : curr + 1
      );
    }, 1500);
  }, [verbiageIdx]);
  return (
    <div
      className="loading-gauze"
      style={{ backgroundImage: "url(assets/images/hex_2.png" }}
    >
      <div className="preloaderWrapper">
        <img src="/assets/images/logo_2.png" className="preloader" />
        <div className="preloaderText">{actionTexts[verbiageIdx]}</div>
      </div>
    </div>
  );
}
