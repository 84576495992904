import { User } from "firebase/auth";
import { useRef } from "react";
import useUserVerificationRequest from "../../../../hooks/UseUserVerificationRequests";
import { Claim } from "../../../../types/claims";
import { Core } from "../../../../types/core";
import {
  VerificationRequest,
  VerificationRequestStatus,
} from "../../../../types/verificationRequests";
import { formatPhoneNumber } from "../../../../utils/verification";
import styles from "./Account.module.scss";
import VerificationRequestRow from "./components/VerificationRequestRow";

export default function Account({
  user,
  claim,
  core,
}: {
  core: Core;
  user: User;
  claim: Claim;
}) {
  const ref = useRef(null);

  const {
    data: { requesterData, verifierData, verifierCoreData },
    status,
  } = useUserVerificationRequest();

  const isRequestingVerification =
    Array.isArray(requesterData) && requesterData.length > 0;
  const isBeingRequestedToVerify =
    Array.isArray(verifierData) && verifierData.length > 0;

  return (
    <>
      <div className={styles.gridContainerMain}>
        <div className={styles.cellWrapper}>
          <div className={styles.cellHeadingRow}>
            <div className={styles.cellHeadingMain}>Invites</div>
          </div>
          <div className={styles.cellMainContent}>
            <div className={styles.cellSubheading}>COPY YOUR INVITE LINK</div>
            <div className={styles.copyInviteWrapper}>
              <input
                value={`${window.location.origin}/#/join?uic=${claim.claimUIC}`}
                className={styles.copyInviteInput}
                readOnly
                ref={ref}
              />
              <button
                className={styles.copyInviteButton}
                onClick={() => {
                  ref.current.select();
                  document.execCommand("copy");
                  ref.current.blur();
                }}
              >
                <img
                  src="/assets/images/copy.svg"
                  className={styles.copyInviteIcon}
                />
                COPY TO CLIPBOARD
              </button>
            </div>
          </div>
        </div>
        {core.verified ||
        isRequestingVerification ||
        isBeingRequestedToVerify ? (
          <div className={styles.cellWrapper}>
            <div className={styles.cellHeadingRow}>
              <div className={styles.cellHeadingMain}>Peer Verification</div>
            </div>
            <div className={styles.cellMainContent}>
              {isRequestingVerification ? (
                <div className={styles.noVerificationRequests}>
                  No users requesting verification at this time.
                </div>
              ) : isBeingRequestedToVerify ? (
                <div className={styles.verificationRequestContainer}>
                  {Array.isArray(verifierCoreData) &&
                    verifierCoreData.map(
                      (request: Core & VerificationRequest) => (
                        <VerificationRequestRow
                          request={request}
                          key={request.ownerId}
                        />
                      )
                    )}
                  {/* <VerificationRequestRow
                    request={{
                      status: VerificationRequestStatus.VERIFIED,
                      requesterPhone: "2055157637",
                      id: "",
                      requester: "",
                      verifier: "",
                      invitersClaim: "",
                      verifiersClaim: "",
                      market: "",
                      areaOfLaw: "",
                      experience: "",
                      role: "",
                      bookOfBusiness: 0,
                      billableHours: 0,
                      hourlyRate: 0,
                      collectedWorkingBillables: 0,
                      salary: 0,
                      bonus: 0,
                      membershipId: "",
                      verified: false,
                      claimUIC: "",
                      ownerId: "",
                    }}
                  /> */}
                </div>
              ) : (
                <div className={styles.noVerificationRequests}>
                  No users requesting verification at this time.
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.cellWrapper}>
            <div className={styles.cellHeadingRow}>
              <div className={styles.cellHeadingMain}>Peer Verification</div>
            </div>
            <div className={styles.cellMainContent}>
              <button
                className={styles.requestVerificationButton}
                onClick={() => {}}
              >
                REQUEST VERIFICATION
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

{
  /* <div className={styles.cellWrapper}>
<div className={styles.cellHeadingRow}>
  <div className={styles.cellHeadingMain}>Manage Phone Number</div>
  <div className={styles.cellHeadingSecondary}>
    {/* LAST UPDATED YESTERDAY 
  </div>
</div>
<div className={styles.cellMainContent}>
  <div className={styles.copyInviteWrapper}>
    <input
      value={user.phoneNumber.replace("+1", "")}
      readOnly
      className={styles.copyInviteInput}
    />
    <button
      className={styles.copyInviteButton}
      onClick={() => {
        ref.current.select();
        document.execCommand("copy");
      }}
    >
      <img
        src="/assets/images/edit.svg"
        className={styles.copyInviteIcon}
      />
      EDIT
    </button>
  </div>
</div>
</div> */
}
