import { doc, DocumentData } from "firebase/firestore";
import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import { Core } from "../types/core";

export default function useUserCoreData(): { data: Core, status: "loading" | "error" | "success" } {
    const { data: user } = useUser();
    const core = doc(
        useFirestore(),
        "core",
        user && "uid" in user ? user.uid : "abc"
    );
    const { status, data } = useFirestoreDocData(core);

    return {
        data: data as Core,
        status
    }
}