import { ConfirmationResult } from "firebase/auth";
import { useState } from "react";
import { confirmWithCode, triggerSignIn } from "../../utils/authentication";
import { cleanPhoneNumber } from "../../utils/normalize";
import styles from "./Login.module.scss";

enum LOGIN_STATE {
  INPUTTING_PHONE_NUMBER,
  INPUTTING_ACCESS_CODE,
}

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();
  const [loginState, setLoginState] = useState<LOGIN_STATE>(
    LOGIN_STATE.INPUTTING_PHONE_NUMBER
  );
  const [warning, setWarning] = useState("");

  return (
    <div className={styles.container} style={{ height: window.outerHeight }}>
      <div className={styles.frameLeft}>
        <div className={styles.upper}>
          <img src="./assets/images/logo_2.png" className={styles.logo} />
        </div>
        <div className={styles.lower}>
          <div className={styles.inputFrame}>
            {loginState === LOGIN_STATE.INPUTTING_PHONE_NUMBER ? (
              <>
                <div className={styles.inputHeading}>PHONE NUMBER</div>
                <input
                  id="sign-in-button"
                  className={styles.input}
                  value={phoneNumber}
                  placeholder="###-###-####"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      if (cleanPhoneNumber(phoneNumber).length > 0) {
                        setWarning("");

                        triggerSignIn({
                          phoneNumber,
                          callback: (confirmationResult) => {
                            setConfirmationResult(confirmationResult);
                            setLoginState(LOGIN_STATE.INPUTTING_ACCESS_CODE);
                          },
                        });
                      }
                    }
                  }}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value.replace(/[^\d-]/gi, ""));
                    setWarning("");
                  }}
                  maxLength={12}
                  type="tel"
                />
                {warning.length > 0 ? (
                  <div className={styles.warning}>{warning}</div>
                ) : (
                  <div className={styles.warningEmpty}></div>
                )}
                <button
                  className={styles.actionButton}
                  onClick={() => {
                    if (cleanPhoneNumber(phoneNumber).length > 0) {
                      setWarning("");

                      triggerSignIn({
                        phoneNumber,
                        callback: (confirmationResult) => {
                          setConfirmationResult(confirmationResult);
                          setLoginState(LOGIN_STATE.INPUTTING_ACCESS_CODE);
                        },
                      });
                    } else {
                      setWarning(
                        "Enter your phone number in the format: ###-###-####"
                      );
                    }
                  }}
                >
                  REQUEST ACCESS CODE
                </button>
              </>
            ) : (
              <>
                <div className={styles.inputHeading}>ACCESS CODE</div>
                <input
                  id="sign-in-button"
                  className={styles.input}
                  value={confirmationCode}
                  placeholder="######"
                  onChange={(e) => {
                    setConfirmationCode(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      if (confirmationCode) {
                        confirmWithCode({
                          success: (user) => {},
                          failure: (error) => {
                            console.warn("Oh no ... WRONG CODE:", { error });
                          },
                          code: confirmationCode,
                          confirmationResult: confirmationResult,
                        });
                      }
                    }
                  }}
                  type="text"
                />
                <button
                  className={styles.actionButton}
                  onClick={() => {
                    if (confirmationCode) {
                      confirmWithCode({
                        success: (user) => {},
                        failure: (error) => {
                          console.warn("Oh no ... WRONG CODE:", { error });
                        },
                        code: confirmationCode,
                        confirmationResult: confirmationResult,
                      });
                    }
                  }}
                >
                  LOG IN WITH CODE
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={styles.frameRight}
        style={{ backgroundImage: 'url("./assets/images/login.png")' }}
      ></div>
    </div>
  );
}
