import { getAuth } from "firebase/auth";
import { doc, getFirestore } from "firebase/firestore";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  AuthProvider,
  FirestoreProvider,
  useFirebaseApp,
  useFirestore,
  useFirestoreDocData,
  useSigninCheck,
  useUser,
} from "reactfire";
import Loader from "../../components/Loader";
import Dashboard from "../Dashboard";
import Join from "../Join";
import LandingPage from "../LandingPage";
import Login from "../Login/Login";
import Profile from "../Profile";
import Terms from "../Terms";
import "./Application.scss";
import Header from "./components/Header/Header";
import NeedInvite from "./components/NeedInvite";
import SubmitData from "./components/SubmitData";
import ReactTooltip from "react-tooltip";
import Messages from "../Messages";
import { ToastContainer, ToastTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Application() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const auth = getAuth(app);
  const firestoreInstance = getFirestore(app);
  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <Core />
      </FirestoreProvider>
    </AuthProvider>
  );
}

const Core = () => {
  const { status, data: signInCheckResult } = useSigninCheck();
  const { status: userStatus, data: user } = useUser();
  const core = doc(
    useFirestore(),
    "core",
    user && "uid" in user ? user.uid : "abc"
  );
  const { status: coreStatus, data: coreData } = useFirestoreDocData(core);

  const isContributor =
    coreData && "NO_ID_FIELD" in coreData && coreData.NO_ID_FIELD.length > 0;

  const hasBeenInvited =
    typeof localStorage.getItem("ec_uic") === "string" &&
    localStorage.getItem("ec_uic").length > 3;

  if (
    userStatus === "loading" ||
    status === "loading" ||
    coreStatus === "loading"
  ) {
    return <Loader />;
  }

  return (
    <div className="application-wrapper">
      <ReactTooltip />
      <ToastContainer />

      <>
        {signInCheckResult.signedIn ? (
          <>
            {isContributor ? (
              <Router>
                <Header />
                <Switch>
                  <Route path="/profile">
                    <Profile />
                  </Route>
                  <Route path="/messages">
                    <Messages />
                  </Route>
                  <Route exact path="/">
                    <Dashboard />
                  </Route>
                  <Redirect to="/" />
                </Switch>
              </Router>
            ) : (
              <Router>
                {hasBeenInvited ? <SubmitData /> : <NeedInvite />}
              </Router>
            )}
          </>
        ) : (
          <Router>
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/join">
                <Join />
              </Route>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/">
                <LandingPage />
              </Route>
              <Redirect to="/" />
            </Switch>
          </Router>
        )}
      </>
    </div>
  );
};
