import styles from "./SubmitData.module.scss";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import { doc, setDoc } from "firebase/firestore";

import {
  AREAS_OF_LAW,
  MARKETS,
  YEARS_OF_EXPERIENCE,
  INTERESTED_IN_RECRUITERS,
} from "../../../../config/constants";
import { useFirestore, useUser } from "reactfire";
import { nanoid } from "nanoid";
import clsx from "clsx";

type OptionType = {
  value: string;
  label: string;
};

export default function SubmitData() {
  const [selectedMarket, setSelectedMarket] = useState<OptionType>();
  const [selectedAreaOfLaw, setSelectedAreaOfLaw] = useState<OptionType>();
  const [selectedYearsOfExperience, setSelectedYearsOfExperience] =
    useState<OptionType>();
  const [activeRole, setActiveRole] = useState("associate");
  const [bookOfBusiness, setBookOfBusiness] = useState("");
  const [billableHours, setbBillableHours] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [collectedWorkingBillables, setCollectedWorkingBillables] =
    useState("");
  const [salary, setSalary] = useState("");
  const [bonus, setBonus] = useState("");

  // ERROR STATE
  const [selectedMarketValid, setSelectedMarketValid] = useState(true);
  const [selectedAreaOfLawValid, setSelectedAreaOfLawValid] = useState(true);
  const [selectedYearsOfExperienceValid, setSelectedYearsOfExperienceValid] =
    useState(true);
  const [activeRoleValid, setActiveRoleValid] = useState(true);
  const [bookOfBusinessValid, setBookOfBusinessValid] = useState(true);
  const [billableHoursValid, setBillableHoursValid] = useState(true);
  const [hourlyRateValid, setHourlyRateValid] = useState(true);
  const [collectedWorkingBillablesValid, setCollectedWorkingBillablesValid] =
    useState(true);
  const [salaryValid, setSalaryValid] = useState(true);
  const [bonusValid, setBonusValid] = useState(true);
  let dollarUSLocale = Intl.NumberFormat("en-US");

  const [compensationFairness, setCompensationFairness] = useState(5);
  const [interestedInRecruiters, setInterestedInRecruiters] =
    useState<OptionType>({ label: "No.", value: "no" });

  // REFS START
  const wrapperRef = useRef(null);

  const selectedMarketRef = useRef(null);
  const selectedAreaOfLawRef = useRef(null);
  const selectedYearsOfExperienceRef = useRef(null);
  const activeRoleRef = useRef(null);
  const bookOfBusinessRef = useRef(null);
  const billableHoursRef = useRef(null);
  const hourlyRateRef = useRef(null);
  const collectedWorkingBillablesRef = useRef(null);
  const salaryRef = useRef(null);
  const bonusRef = useRef(null);
  // REFS END

  const cleanInput = (value: string) => {
    return value.replace(/[^\d,]/gi, "");
  };

  const getJustNumbers = (value: string) => {
    return Number(value.replace(/,/gi, ""));
  };

  const { status: userStatus, data: user } = useUser();
  const db = useFirestore();

  const claimsRef: { [key: string]: string } = {
    "5fXIMRQLoa": "Craig",
    _lCc3KbpCb: "Justin",
    czBGsjlZlw: "Craig LinkedIn message",
    we4h4ystUp: "Mailer",
  };

  return (
    <div className={styles.pageWrapper} ref={wrapperRef}>
      <img src="./assets/images/logo_2.png" className={styles.logo} />

      <div className={styles.introBlurb}>
        To promote transparency and honesty, we request the following data. It
        will be listed <strong>anonymously</strong> alongside peers.
      </div>

      <div className={styles.formWrapper}>
        <div className={styles.formWrapperLabel}>CONTEXT</div>
        <div className={styles.formElementLabel} ref={selectedMarketRef}>
          MARKET
        </div>
        <Select<OptionType>
          options={MARKETS}
          className={clsx(styles.formElement, {
            [styles.error]: !selectedMarketValid,
          })}
          onChange={(value) => {
            setSelectedMarketValid(true);
            setSelectedMarket(value);
          }}
        />
        <div className={styles.formElementLabel} ref={selectedAreaOfLawRef}>
          PRACTICE AREA
        </div>
        <Select<OptionType>
          options={AREAS_OF_LAW}
          className={clsx(styles.formElement, {
            [styles.error]: !selectedAreaOfLawValid,
          })}
          onChange={(value) => {
            setSelectedAreaOfLawValid(true);
            setSelectedAreaOfLaw(value);
          }}
        />
        <div
          className={styles.formElementLabel}
          ref={selectedYearsOfExperienceRef}
        >
          YEARS OF EXPERIENCE
        </div>
        <Select<OptionType>
          options={YEARS_OF_EXPERIENCE}
          className={clsx(styles.formElement, {
            [styles.error]: !selectedYearsOfExperienceValid,
          })}
          onChange={(value) => {
            setSelectedYearsOfExperienceValid(true);
            setSelectedYearsOfExperience(value);
          }}
        />
        <div className={styles.formElementLabel} ref={activeRoleRef}>
          ROLE
        </div>
        <div className={styles.toggleSelectWrapper}>
          <div className={`${styles.toggleSelectActive} ${styles[activeRole]}`}>
            {activeRole === "associate"
              ? "ASSOCIATE"
              : activeRole === "ofCounsel"
              ? "OF COUNSEL"
              : "PARTNER"}
          </div>
          <div
            onClick={() => {
              setActiveRole("associate");
            }}
            className={styles.toggleSelectInactive}
          >
            ASSOCIATE
          </div>
          <div
            onClick={() => {
              setActiveRole("ofCounsel");
            }}
            className={styles.toggleSelectInactive}
          >
            OF COUNSEL
          </div>
          <div
            onClick={() => {
              setActiveRole("partner");
            }}
            className={styles.toggleSelectInactive}
          >
            PARTNER
          </div>
        </div>
      </div>
      <div className={styles.formWrapper}>
        <div className={styles.formWrapperLabel}>FINANCIAL</div>

        <div className={styles.formElementLabel} ref={bookOfBusinessRef}>
          BOOK OF BUSINESS
        </div>
        <div className={styles.formElementFinancialRow}>
          <div className={styles.formElementDollar}>$</div>

          <input
            className={clsx(styles.formTextInput, {
              [styles.error]: !bookOfBusinessValid,
            })}
            value={dollarUSLocale.format(getJustNumbers(bookOfBusiness))}
            onChange={(e) => {
              setBookOfBusinessValid(true);
              setBookOfBusiness(cleanInput(e.target.value));
            }}
            placeholder="Book of business"
          />
        </div>
        <div className={styles.formElementLabel} ref={billableHoursRef}>
          PERSONAL BILLABLE HOURS
        </div>
        <div className={styles.formElementFinancialRow}>
          <input
            className={clsx(styles.formTextInput, {
              [styles.error]: !billableHoursValid,
            })}
            value={dollarUSLocale.format(getJustNumbers(billableHours))}
            onChange={(e) => {
              setBillableHoursValid(true);
              setbBillableHours(cleanInput(e.target.value));
            }}
            placeholder="Personal Billable Hours"
          />
        </div>
        <div className={styles.formElementLabel} ref={hourlyRateRef}>
          HOURLY RATE
        </div>
        <div className={styles.formElementFinancialRow}>
          <div className={styles.formElementDollar}>$</div>

          <input
            className={clsx(styles.formTextInput, {
              [styles.error]: !hourlyRateValid,
            })}
            value={dollarUSLocale.format(getJustNumbers(hourlyRate))}
            onChange={(e) => {
              setHourlyRateValid(true);
              setHourlyRate(cleanInput(e.target.value));
            }}
            placeholder="Hourly rate"
          />
        </div>
        <div className={styles.formElementLabel}>
          ANNUAL WORKING BILLABLES - GROSS
        </div>
        <div className={styles.formElementFinancialRow}>
          <div className={styles.formElementDollar}>$</div>
          <input
            className={clsx(styles.formTextInput)}
            value={dollarUSLocale.format(
              getJustNumbers(billableHours) * getJustNumbers(hourlyRate)
            )}
            disabled
          />
        </div>

        <div
          className={styles.formElementLabel}
          ref={collectedWorkingBillablesRef}
        >
          ANNUAL WORKING BILLABLES - COLLECTED
        </div>
        <div className={styles.formElementFinancialRow}>
          <div className={styles.formElementDollar}>$</div>
          <input
            className={clsx(styles.formTextInput, {
              [styles.error]: !collectedWorkingBillablesValid,
            })}
            value={dollarUSLocale.format(
              getJustNumbers(collectedWorkingBillables)
            )}
            onChange={(e) => {
              setCollectedWorkingBillablesValid(true);
              setCollectedWorkingBillables(cleanInput(e.target.value));
            }}
            placeholder="Collected billables"
          />
        </div>

        <div className={styles.formElementLabel} ref={salaryRef}>
          SALARY
        </div>
        <div className={styles.formElementFinancialRow}>
          <div className={styles.formElementDollar}>$</div>

          <input
            className={clsx(styles.formTextInput, {
              [styles.error]: !salaryValid,
            })}
            value={dollarUSLocale.format(getJustNumbers(salary))}
            onChange={(e) => {
              setSalaryValid(true);
              setSalary(cleanInput(e.target.value));
            }}
            placeholder="Salary"
          />
        </div>
        <div className={styles.formElementLabel} ref={bonusRef}>
          BONUS
        </div>
        <div className={styles.formElementFinancialRow}>
          <div className={styles.formElementDollar}>$</div>

          <input
            className={clsx(styles.formTextInput, {
              [styles.error]: !bonusValid,
            })}
            value={dollarUSLocale.format(getJustNumbers(bonus))}
            onChange={(e) => {
              setBonusValid(true);
              setBonus(cleanInput(e.target.value));
            }}
            placeholder="Bonus"
          />
        </div>
        <div className={styles.formElementLabel} ref={bonusRef}>
          HOW FAIRLY ARE YOU COMPENSATED?
        </div>
        <div className={styles.formElementFinancialRow}>
          <input
            type="range"
            min="1"
            max="10"
            onChange={(e) => {
              setCompensationFairness(Number(e.target.value));
            }}
            value={compensationFairness}
            className={styles.slider}
            id="myRange"
          ></input>
          <div className={styles.compensationFairnessLabels}>
            <div className={styles.compensationFairnessLabel}>UNDER PAID</div>
            <div className={styles.compensationFairnessLabel}>OVER PAID</div>
          </div>
        </div>
        <div className={styles.formElementLabel} ref={bonusRef}>
          RECEIVE RECRUITER OFFERS BY TEXT? <span className={styles.formElementLabelSecondary}><br/>(Opt out at any time)</span>
        </div>
        <Select<OptionType>
          options={INTERESTED_IN_RECRUITERS}
          className={clsx(styles.formElement)}
          value={interestedInRecruiters}
          onChange={(value) => {
            setInterestedInRecruiters(value);
          }}
        />
      </div>

      <button
        className={styles.submit}
        onClick={async () => {
          const allValuesArr = [
            {
              key: "selectedMarket",
              value: selectedMarket && selectedMarket.value,
              ref: selectedMarketRef,
              validitySetter: setSelectedMarketValid,
            },
            {
              key: "selectedAreaOfLaw",
              value: selectedAreaOfLaw && selectedAreaOfLaw.value,
              ref: selectedAreaOfLawRef,
              validitySetter: setSelectedAreaOfLawValid,
            },
            {
              key: "selectedYearsOfExperience",
              value:
                selectedYearsOfExperience && selectedYearsOfExperience.value,
              ref: selectedYearsOfExperienceRef,
              validitySetter: setSelectedYearsOfExperienceValid,
            },
            {
              key: "activeRole",
              value: activeRole,
              ref: activeRoleRef,
              validitySetter: setActiveRoleValid,
            },
            {
              key: "bookOfBusiness",
              value: bookOfBusiness,
              ref: bookOfBusinessRef,
              validitySetter: setBookOfBusinessValid,
            },
            {
              key: "billableHours",
              value: billableHours,
              ref: billableHoursRef,
              validitySetter: setBillableHoursValid,
            },
            {
              key: "hourlyRate",
              value: hourlyRate,
              ref: hourlyRateRef,
              validitySetter: setHourlyRateValid,
            },
            {
              key: "collectedWorkingBillables",
              value: collectedWorkingBillables,
              ref: collectedWorkingBillablesRef,
              validitySetter: setCollectedWorkingBillablesValid,
            },
            {
              key: "salary",
              value: salary,
              ref: salaryRef,
              validitySetter: setSalaryValid,
            },
            {
              key: "bonus",
              value: bonus,
              ref: bonusRef,
              validitySetter: setBonusValid,
            },
          ];

          let hasAllValues = true;

          let missingValues = [] as {
            key: string;
            value: string | number;
            ref: React.MutableRefObject<any>;
            validitySetter: (value: boolean) => void;
          }[];

          wrapperRef.current.scrollIntoView();

          allValuesArr.forEach(
            ({
              key,
              value,
              ref,
              validitySetter,
            }: {
              key: string;
              value: string | number;
              ref: React.MutableRefObject<any>;
              validitySetter: (value: boolean) => void;
            }) => {
              if (
                !value ||
                (typeof value === "string" && value.length === 0) ||
                (typeof value === "number" && value === 0)
              ) {
                hasAllValues = false;

                validitySetter(false);

                missingValues.push({
                  key,
                  value,
                  ref,
                  validitySetter,
                });
              } else {
                validitySetter(true);
              }
            }
          );

          if (missingValues.length > 0) {
            setTimeout(() => {
              missingValues[0].ref.current.scrollIntoView();
            }, 0);
          }

          const localClaimUIC = localStorage.getItem("ec_uic");

          if (hasAllValues) {
            const allValues = {
              market: selectedMarket.value,
              areaOfLaw: selectedAreaOfLaw.value,
              experience: selectedYearsOfExperience.value,
              role: activeRole,
              bookOfBusiness: getJustNumbers(bookOfBusiness),
              billableHours: getJustNumbers(billableHours),
              hourlyRate: getJustNumbers(hourlyRate),
              collectedWorkingBillables: getJustNumbers(
                collectedWorkingBillables
              ),
              salary: getJustNumbers(salary),
              bonus: getJustNumbers(bonus),
              membershipId: `${nanoid(3).toUpperCase()}-${nanoid(
                3
              ).toUpperCase()}`,
              verified: false,
              claimUIC: localClaimUIC,
              id: user.uid,
              ownerId: user.uid,
              perceivedCompensationFairness: compensationFairness,
              invitedByPlain:
                localClaimUIC && localClaimUIC in claimsRef
                  ? (claimsRef[localClaimUIC] as string)
                  : "Another member",
              interestedInRecruiters: interestedInRecruiters.value,
            };

            await setDoc(doc(db, "core", user.uid), allValues);
            await setDoc(doc(db, "claims", user.uid), {
              claimUIC: nanoid(10),
              id: user.uid,
              ownerId: user.uid,
              phone: user.providerData[0].phoneNumber,
              claimUICCreatedWith: localClaimUIC,
            });
          }
        }}
      >
        SUBMIT
      </button>
    </div>
  );
}
