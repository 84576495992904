import { doc, DocumentData } from "firebase/firestore";
import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import { Claim } from "../types/claims";

export default function useUserClaimData(): { data: DocumentData, status: "loading" | "error" | "success" } {
    const { data: user } = useUser();
    const claim = doc(
        useFirestore(),
        "claims",
        user && "uid" in user ? user.uid : "abc"
    );
    const { status, data } = useFirestoreDocData(claim);

    return {
        data: data as Claim,
        status
    }
}