import styles from "./PerformanceCard.module.scss";
import { CoreAmplified } from "../../../../types/core";
import CompensationScoreBar from "./components/CompensationScoreBar";
import CoreCell from "./components/CoreCell";

export default function PerformanceCard({
  coreAmplified,
  isUser = false,
}: {
  coreAmplified: CoreAmplified;
  isUser?: boolean;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.dataWrapper}>
        <div className={styles.contextCell}>
          <div className={styles.contextCellValue}>
            {coreAmplified.membershipId}
          </div>
          <div className={styles.contextCellLabel}>MEMBERSHIP ID</div>
        </div>
        <div className={styles.contextCell}>
          <div className={styles.contextCellValue}>{coreAmplified.market}</div>
          <div className={styles.contextCellLabel}>MARKET</div>
        </div>
        <div className={styles.contextCell}>
          <div className={styles.contextCellValue}>
            {coreAmplified.areaOfLaw}
          </div>
          <div className={styles.contextCellLabel}>PRACTICE AREA</div>
        </div>
        <div className={styles.contextCell}>
          <div className={styles.contextCellValue}>
            {coreAmplified.experience} YEARS
          </div>
          <div className={styles.contextCellLabel}>EXPERIENCE</div>
        </div>
        <CoreCell
          coreAmplifiedDataPoint={coreAmplified.hourlyRate}
          label={"HOURLY RATE"}
        />
        <CoreCell
          coreAmplifiedDataPoint={coreAmplified.bookOfBusiness}
          label={"BOOK OF BUSINESS"}
        />

        <CoreCell
          coreAmplifiedDataPoint={coreAmplified.billableHours}
          label={"BILLABLE HOURS"}
          isCurrency={false}
        />
        <CoreCell
          coreAmplifiedDataPoint={coreAmplified.salary}
          label={"BASE SALARY"}
        />
        <CoreCell
          coreAmplifiedDataPoint={coreAmplified.grossWorkingBillables}
          label={"GROSS BILLABLES"}
        />
        {/* <CoreCell
          coreAmplifiedDataPoint={coreAmplified.bonus}
          label={"BONUS"}
        /> */}
        {/* <CoreCell
          coreAmplifiedDataPoint={coreAmplified.collectedWorkingBillables}
          label={"COLLECTED BILLABLES"}
        /> */}
        <CoreCell
          coreAmplifiedDataPoint={coreAmplified.totalCompensation}
          label={"TOTAL COMPENSATION"}
        />
      </div>
      <CompensationScoreBar
        membershipId={coreAmplified.membershipId}
        ownerId={coreAmplified.ownerId}
        isUser={isUser}
      />
    </div>
  );
}
