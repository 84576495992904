import {
    collection,
    doc,
    query,
    setDoc,
    where
} from "firebase/firestore";
import { nanoid } from "nanoid";
import {
    useFirestore,
    useFirestoreCollectionData,
    useUser
} from "reactfire";
import { MessageType } from "../types/chat";
import useUserCoreData from "./UseUserCoreData";

export default function useInitializeChat(): {
    initialize: (config: {
        receiverId: string;
        receiverMembershipId: string;
        onSuccess: (conversationId: string) => void;
    }) => void;
} {
    const DB = useFirestore();
    const { data: user } = useUser();

    const { data: userCore, status: userCoreStatus } = useUserCoreData();

    const chatCollection = collection(DB, "chats");
    const chatSenderQuery = query(
        chatCollection,
        where("senderId", "==", user.uid)
    );
    const chatReceiverQuery = query(
        chatCollection,
        where("receiverId", "==", user.uid)
    );

    let { status: sentDataStatus, data: sentData } =
        useFirestoreCollectionData(chatSenderQuery);

    let { status: receiveDataStatus, data: receiveData } =
        useFirestoreCollectionData(chatReceiverQuery);



    const initialize = async ({
        receiverId,
        receiverMembershipId,
        onSuccess
    }: {
        receiverId: string;
        receiverMembershipId: string;
        onSuccess: (conversationId: string) => void;
    }) => {

        const joined = [...sentData, ...receiveData];
        const sintenels = joined.filter(message => message.type === MessageType.SENTINEL)

        const messageAlreadyExists = sintenels.find(conversation => conversation.senderId === receiverId || conversation.receiverId === receiverId);

        if (messageAlreadyExists) {
            onSuccess(messageAlreadyExists.conversationId);

        } else {
            const conversationId = nanoid();
            const id = nanoid();

            await setDoc(doc(DB, "chats", id), {
                id,
                body: '',
                senderId: user.uid,
                conversationId,
                seen: true,
                receiverId,
                senderMembershipId: userCore.membershipId,
                receiverMembershipId,
                type: MessageType.SENTINEL
            });

            onSuccess(conversationId);
        }

    }

    return {
        initialize
    };
}
