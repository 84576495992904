import {
    collection,
    doc,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import {
    useFirestore,
    useFirestoreCollectionData,
    useUser
} from "reactfire";
import { Chat, Conversation, MessageType } from "../types/chat";
import useUserCoreData from "./UseUserCoreData";

export default function useChat(): {
    data: Conversation[];
    isLoading: boolean;
    send: (config: {
        message: string;
        receiverId: string;
        receiverMembershipId: string;
        conversationId: string;
    }) => void;
    markAsSeen: (config: {
        conversationId: string;
    }) => void
} {
    const DB = useFirestore();
    const { data: user } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<Conversation[]>([]);

    const { data: userCore, status: userCoreStatus } = useUserCoreData();

    const chatCollection = collection(DB, "chats");
    const chatSenderQuery = query(
        chatCollection,
        where("senderId", "==", user?.uid || "")
    );
    const chatReceiverQuery = query(
        chatCollection,
        where("receiverId", "==", user?.uid || "")
    );

    let { status: sentDataStatus, data: sentData } =
        useFirestoreCollectionData(chatSenderQuery);

    let { status: receiveDataStatus, data: receiveData } =
        useFirestoreCollectionData(chatReceiverQuery);

    useEffect(() => {
        if (receiveDataStatus === "success" && sentDataStatus === "success" && userCoreStatus === "success") {
            setIsLoading(false);

            const joined = [...sentData, ...receiveData];
            const messages = joined.filter(message => message.type === MessageType.MESSAGE).sort((a, b) => a.timestamp - b.timestamp);
            const sintenels = joined.filter(message => message.type === MessageType.SENTINEL).sort((a, b) => a.timestamp - b.timestamp);
            if (sintenels.length > 0) {
                const conversations = sintenels.map(sintenel => {
                    const currentUsersMessages = messages.filter(
                        (chat) => chat.conversationId === sintenel.conversationId
                    ) as Chat[];
                    return {
                        id: nanoid(),
                        otherMemberId: sintenel.receiverId === user.uid ? sintenel.senderId : sintenel.receiverId,
                        conversationId: sintenel.conversationId,
                        membershipId: sintenel.senderMembershipId === userCore.membershipId ? sintenel.receiverMembershipId : sintenel.senderMembershipId,
                        hasUnseen: !!currentUsersMessages.filter((chat) => chat.receiverId === user.uid).find(
                            (chat) => {
                                return !chat.seen
                            }
                        ),
                        messages: currentUsersMessages,
                    }
                })

                setData(conversations);
            } else {
                setData([]);
            }
        } else {
            setIsLoading(true);
        }
    }, [sentDataStatus, receiveDataStatus, sentData, receiveData, userCore, userCoreStatus]);

    const send = async ({
        message,
        receiverId,
        receiverMembershipId,
        conversationId
    }: {
        message: string;
        receiverId: string;
        receiverMembershipId: string;
        conversationId: string;
    }) => {
        const id = nanoid(10);

        await setDoc(doc(DB, "chats", id), {
            id,
            body: message,
            senderId: user.uid,
            timestamp: serverTimestamp(),
            seen: false,
            conversationId,
            receiverId,
            senderMembershipId: userCore.membershipId,
            receiverMembershipId,
            type: MessageType.MESSAGE
        });

    }

    const markAsSeen = async ({ conversationId }: { conversationId: string; }) => {
        if (receiveDataStatus === "success") {
            const currentConversation = receiveData.filter((chat: Chat) => {
                return chat.conversationId === conversationId
            })

            currentConversation.forEach(async (chat: Chat) => {
                const chatRef = doc(DB, "chats", chat.id);
                await updateDoc(chatRef, {
                    seen: true
                });
            });
        }
    }

    return {
        data,
        isLoading,
        send,
        markAsSeen
    };
}
