import styles from "./NotQualified.module.scss";

export default function NotQualified() {
  return (
    <div className={styles.container} style={{ height: window.outerHeight }}>
      <div className={styles.frameLeft}>
        <div className={styles.upper}>
          <img src="./assets/images/logo_2.png" className={styles.logo} />
        </div>
        <div className={styles.lower}>
          <div className={styles.infoFrame}>
            We're sorry you do not qualify at this time. As our community grows,
            we will look for opportunities to extend access to users who do not
            qualify for traditional membership.
            <br />
            <br />
            Think you should have access?{" "}
            <a
              href="mailto:contact@elitecompensation.com"
              className={styles.link}
            >
              Click here to contact
            </a>{" "}
            our Attorney Success Team for a manual review.
          </div>
        </div>
      </div>
      <div
        className={styles.frameRight}
        style={{ backgroundImage: 'url("./assets/images/verify.png")' }}
      ></div>
    </div>
  );
}
