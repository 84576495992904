import clsx from "clsx";
import millify from "millify";
import { CoreAmplifiedDataPoint } from "../../../../../../types/core";
import { valueComparisonClass } from "../../../../../../utils/core/core";
import styles from "./CoreCell.module.scss";

export default function CoreCell({
  coreAmplifiedDataPoint: { value, diff, rank },
  label,
  isCurrency = true,
}: {
  coreAmplifiedDataPoint: CoreAmplifiedDataPoint;
  label: string;
  isCurrency?: boolean;
}) {
  let dollarUSLocale = Intl.NumberFormat("en-US");

  return (
    <div className={styles.coreCell}>
      <div className={styles.coreLabel}>{label}</div>
      <div className={styles.coreCellContent}>
        <div className={styles.coreNumber}>
          {isCurrency ? "$" : ""}
          {isCurrency ? millify(value) : dollarUSLocale.format(value)}
        </div>
        <div className={styles.coreNumberMeta}>
          <div className={styles.coreNumberMetaPoint}>
            <div className={styles.coreNumberMetaPointKey}>RANK</div>
            <div className={styles.coreNumberMetaPointValue}>{rank}</div>
          </div>
          {/* <div className={styles.coreNumberMetaPoint}>
            <div className={styles.coreNumberMetaPointKey}>YOU</div>
            <div
              className={clsx(
                styles.coreNumberMetaPointValue,
                valueComparisonClass(diff)
              )}
            >
              {isCurrency && diff !== 0 ? "$" : ""}
              {diff !== 0 ? millify(Math.abs(diff)) : "="}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
