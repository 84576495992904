import clsx from "clsx";
import millify from "millify";
import useUserVerificationRequest from "../../../../../../hooks/UseUserVerificationRequests";
import { Core } from "../../../../../../types/core";
import {
  VerificationRequest,
  VerificationRequestStatus,
} from "../../../../../../types/verificationRequests";
import { formatPhoneNumber } from "../../../../../../utils/verification";
import styles from "./VerificationRequestRow.module.scss";

export default function VerificationRequestRow({
  request,
}: {
  request: Core & VerificationRequest;
}) {
  const { verifyUser, unverifyUser } = useUserVerificationRequest();

  return (
    <div className={styles.verificationRequestWrapper}>
      {request.status === VerificationRequestStatus.VERIFIED ? (
        <div
          className={clsx(styles.verificationRequestHeading, styles.outcome)}
        >
          <div
            className={clsx(styles.verificationRequestOutcome, styles.positive)}
          >
            <img
              src="/assets/images/check.png"
              className={styles.verificationRequestOutcomeIcon}
            />
          </div>
          <div className={styles.headingVerbiage}>
            You verified this member.
          </div>
          <div className={styles.verificationRequestHeadingNumber}>
            {formatPhoneNumber(request.requesterPhone.replace("+1", ""))}
          </div>
        </div>
      ) : request.status === VerificationRequestStatus.INVALID ? (
        <div
          className={clsx(styles.verificationRequestHeading, styles.outcome)}
        >
          <div
            className={clsx(styles.verificationRequestOutcome, styles.negative)}
          >
            <img
              src="/assets/images/cancel.png"
              className={styles.verificationRequestOutcomeIcon}
            />
          </div>
          <div className={styles.headingVerbiage}>
            You marked this claim as innacurate.
          </div>
          <div className={styles.verificationRequestHeadingNumber}>
            {formatPhoneNumber(request.requesterPhone.replace("+1", ""))}
          </div>
        </div>
      ) : (
        <>
          <div className={styles.verificationRequestHeading}>
            <div className={styles.headingVerbiage}>
              A member wants you to verify their data
            </div>
            <div className={styles.verificationRequestHeadingNumber}>
              {formatPhoneNumber(request.requesterPhone.replace("+1", ""))}
            </div>
          </div>
          <div className={styles.verificationRequestSubheadingRow}>
            <div className={styles.verificationRequestSubheadingCell}>
              <div className={styles.verificationRequestSubheadingCellLabel}>
                PRACTICE AREA
              </div>
              <div className={styles.verificationRequestSubheadingCellContent}>
                {request.areaOfLaw}
              </div>
            </div>
            <div className={styles.verificationRequestSubheadingCell}>
              <div className={styles.verificationRequestSubheadingCellLabel}>
                EXPERIENCE
              </div>
              <div className={styles.verificationRequestSubheadingCellContent}>
                {request.experience} years
              </div>
            </div>
            <div className={styles.verificationRequestSubheadingCell}>
              <div className={styles.verificationRequestSubheadingCellLabel}>
                MARKET
              </div>
              <div className={styles.verificationRequestSubheadingCellContent}>
                {request.market}
              </div>
            </div>
            <div className={styles.verificationRequestSubheadingCell}>
              <div className={styles.verificationRequestSubheadingCellLabel}>
                ROLE
              </div>
              <div className={styles.verificationRequestSubheadingCellContent}>
                {request.role}
              </div>
            </div>
          </div>
          <div className={styles.verificationRequestDetailWrapper}>
            <div className={styles.verificationRequestDetailElement}>
              <div className={styles.verificationRequestDetailElementLabel}>
                BOOK OF BUSINESS
              </div>
              <div className={styles.verificationRequestDetailElementNumber}>
                ${millify(request.bookOfBusiness)}
              </div>
            </div>
            <div className={styles.verificationRequestDetailElement}>
              <div className={styles.verificationRequestDetailElementLabel}>
                HOURLY RATE
              </div>
              <div className={styles.verificationRequestDetailElementNumber}>
                ${millify(request.hourlyRate)}
              </div>
            </div>
            <div className={styles.verificationRequestDetailElement}>
              <div className={styles.verificationRequestDetailElementLabel}>
                WORKING BILLABLES
              </div>
              <div className={styles.verificationRequestDetailElementNumber}>
                ${millify(request.billableHours * request.hourlyRate)}
              </div>
            </div>
            <div className={styles.verificationRequestDetailElement}>
              <div className={styles.verificationRequestDetailElementLabel}>
                SALARY
              </div>
              <div className={styles.verificationRequestDetailElementNumber}>
                ${millify(request.salary)}
              </div>
            </div>
            <div className={styles.verificationRequestDetailElement}>
              <div className={styles.verificationRequestDetailElementLabel}>
                BILLABLE HOURS
              </div>
              <div className={styles.verificationRequestDetailElementNumber}>
                {millify(request.salary)}
              </div>
            </div>
            <div className={styles.verificationRequestDetailElement}>
              <div className={styles.verificationRequestDetailElementLabel}>
                BONUS
              </div>
              <div className={styles.verificationRequestDetailElementNumber}>
                ${millify(request.bonus)}
              </div>
            </div>
          </div>

          <div className={styles.verificationRequestActionsRow}>
            <div
              className={`${styles.verificationAction} ${styles.negative}`}
              onClick={() => {
                unverifyUser({ requestID: request.id });
              }}
            >
              <div
                className={`${styles.verificationActionIconWrapper} ${styles.negative}`}
              >
                <img
                  src="/assets/images/cancel.png"
                  className={styles.verificationActionIcon}
                />
              </div>
              <div className={`${styles.verificationActionLabel}`}>
                THIS LOOKS <span className={styles.emphasis}>WRONG</span>
              </div>
            </div>
            <div
              className={`${styles.verificationAction}  ${styles.positive}`}
              onClick={() => {
                verifyUser({ requestID: request.id, userUID: request.ownerId });
              }}
            >
              <div
                className={`${styles.verificationActionIconWrapper} ${styles.positive}`}
              >
                <img
                  src="/assets/images/check.png"
                  className={styles.verificationActionIcon}
                />
              </div>
              <div className={`${styles.verificationActionLabel}`}>
                THIS LOOKS <span className={styles.emphasis}>RIGHT</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
