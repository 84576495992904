import styles from "./VerifyEligibility.module.scss";

export default function VerifyEligibility({
  onClickVerify,
  onClickNotQualified,
}: {
  onClickVerify: () => void;
  onClickNotQualified: () => void;
}) {
  return (
    <div className={styles.container} style={{ height: window.outerHeight }}>
      <div className={styles.frameLeft}>
        <div className={styles.upper}>
          <img src="./assets/images/logo_2.png" className={styles.logo} />
        </div>
        <div className={styles.lower}>
          <div className={styles.validClaim}>
            INVITE CODE{" "}
            <span className={styles.emphasis}>SUCCESSFULLY VALIDATED</span>
          </div>
          <div className={styles.infoFrame}>
            <div className={styles.infoVerbiage}>
              To qualify for access, attorneys must make over 
              <span className={styles.emphasis}> $200,000 annually</span> and be interested in raises of 
              <span className={styles.emphasis}> $50,000 or more</span>.
            </div>
            <div className={styles.confirmWrapper}>
              <button
                className={styles.confirm}
                onClick={() => onClickVerify()}
              >
                YES. I QUALIFY.
              </button>
            </div>
          </div>
          <div className={styles.unqualifiedApplicatedWrapper}>
            <button
              className={styles.unqualifiedApplicated}
              onClick={() => onClickNotQualified()}
            >
              I DO NOT QUALIFY, BUT WANT TO JOIN.
            </button>
          </div>
        </div>
      </div>
      <div
        className={styles.frameRight}
        style={{ backgroundImage: 'url("./assets/images/verify.png")' }}
      ></div>
    </div>
  );
}
