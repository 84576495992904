import { useEffect, useState } from "react";
import { useUser } from "reactfire";
import { Claim } from "../../types/claims";
import { Core } from "../../types/core";
import Account from "./components/Account";
import Overview from "./components/Overview";
import styles from "./Profile.module.scss";
import ReactTooltip from "react-tooltip";
import useUserClaimData from "../../hooks/UseUserClaimData";
import useUserCoreData from "../../hooks/UseUserCoreData";
import useUserVerificationRequest from "../../hooks/UseUserVerificationRequests";
import { useLocation, useHistory } from "react-router-dom";
import usePublicCoreData from "../../hooks/UsePublicCoreData";
import Loader from "../../components/Loader";
import useInitializeChat from "../../hooks/UseInitializeChat";

export default function Profile() {
  const history = useHistory();
  const { data: user } = useUser();
  const [activeTab, setActiveTab] = useState("overview");
  const [isPublicProfile, setIsPublicProfile] = useState(false);

  const { status: coreStatus, data: coreData } = useUserCoreData();
  const [activeChat, setActiveChat] = useState("");
  const { data: claimData } = useUserClaimData();
  const {
    data: { requesterData },
    requestVerification,
  } = useUserVerificationRequest();

  const { pathname } = useLocation();

  const membershipID = pathname ? pathname.replace("/profile/", "") : "";

  const { data: publicData, status: publicDataStatus } = usePublicCoreData({
    membershipID,
  });

  const isRequestingVerification =
    Array.isArray(requesterData) && requesterData.length > 0;

  if (
    membershipID.replace("/profile", "") &&
    membershipID.replace("/profile", "").length > 0 &&
    publicDataStatus !== "loading" &&
    publicData &&
    coreData.membershipId === publicData.membershipId
  ) {
    history.push("/profile");
  }

  const activeCoreData = (publicData || coreData) as Core;

  const { initialize } = useInitializeChat();

  useEffect(() => {
    if (
      coreStatus !== "loading" &&
      publicDataStatus !== "loading" &&
      membershipID.length > 0 &&
      publicData &&
      coreData.membershipId !== publicData.membershipId
    ) {
      setIsPublicProfile(true);
    }
  }, [publicData, coreData, coreStatus, publicDataStatus, membershipID]);

  if (coreStatus === "loading" || publicDataStatus === "loading") {
    return <Loader />;
  }

  return (
    <div>
      <ReactTooltip />
      <div className={styles.heading}>
        <div className={styles.headingSection}>
          <div className={styles.headingSectionLabel}>MEMBERSHIP ID</div>
          <div
            className={`${styles.headingSectionBody} ${styles.membershipId}`}
          >
            {activeCoreData.membershipId}
          </div>
        </div>
        <div className={styles.headingSection}>
          <div className={styles.headingSectionLabel}>VERIFICATION STATUS</div>
          <div className={styles.headingSectionBody}>
            {activeCoreData.verified ? (
              <div
                className={styles.verifiedBadge}
                data-tip={`${
                  isPublicProfile
                    ? "This member has had their data verified"
                    : "Your data has been verified"
                }`}
              >
                <img
                  className={styles.verifiedIcon}
                  src="/assets/images/verified.svg"
                />
                VERIFIED
              </div>
            ) : isRequestingVerification ? (
              <div
                className={styles.verifyingBadge}
                onClick={() => {
                  if (!isPublicProfile) {
                    requestVerification();
                  }
                }}
                data-tip={`${
                  isPublicProfile
                    ? "The member's data is being verified"
                    : "A peer is verifying your data"
                }`}
              >
                VERIFYING
              </div>
            ) : (
              <div
                className={styles.notVerifiedBadge}
                onClick={() => {
                  if (!isPublicProfile) {
                    requestVerification();
                  }
                }}
                data-tip="Get verified"
              >
                NOT VERIFIED
              </div>
            )}
          </div>
        </div>
        <div className={styles.headingSection}>
          <div className={styles.headingSectionLabel}>MARKET</div>
          <div className={styles.headingSectionBody}>
            {activeCoreData.market}
          </div>
        </div>
        <div className={styles.headingSection}>
          <div className={styles.headingSectionLabel}>PRACTICE AREA</div>
          <div className={styles.headingSectionBody}>
            {activeCoreData.areaOfLaw}
          </div>
        </div>
        <div className={styles.headingSection}>
          <div className={styles.headingSectionLabel}>EXPERIENCE</div>
          <div className={styles.headingSectionBody}>
            {activeCoreData.experience} Years
          </div>
        </div>
        <div className={styles.headingSection}>
          <div className={styles.headingSectionLabel}>ROLE</div>
          <div className={`${styles.headingSectionBody} ${styles.role}`}>
            {activeCoreData.role === "ofCounsel"
              ? "Of Counsel"
              : activeCoreData.role}
          </div>
        </div>
        {isPublicProfile && (
          <button
            className={styles.headingMessage}
            data-tip="Message this member"
            onClick={() => {
              initialize({
                receiverId: activeCoreData.ownerId,
                receiverMembershipId: membershipID,
                onSuccess: (conversationId: string) => {
                  history.push(`/messages/${conversationId}`);
                },
              });
            }}
          >
            MESSAGE
          </button>
        )}
      </div>
      {!isPublicProfile && (
        <div className={styles.tabWrapper}>
          <div
            onClick={() => {
              setActiveTab("overview");
            }}
            className={`${styles.tab} ${
              activeTab === "overview" ? styles.active : ""
            }`}
          >
            OVERVIEW
          </div>
          <div
            onClick={() => {
              setActiveTab("account");
            }}
            className={`${styles.tab} ${
              activeTab === "account" ? styles.active : ""
            }`}
          >
            ACCOUNT
          </div>
        </div>
      )}
      <div className={styles.profileContent}>
        {activeTab === "overview" ? (
          <Overview core={activeCoreData as Core} />
        ) : (
          <Account
            user={user}
            claim={claimData as Claim}
            core={activeCoreData as Core}
          />
        )}
      </div>
    </div>
  );
}
