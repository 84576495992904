import { useEffect, useState } from "react";
import { Core, CoreAmplified } from "../types/core";
import { getRankFromSortedCore, sortCoreByKey } from "../utils/core/core";
import useAllCoreData from "./UseAllCoreData";
import useUserCoreData from "./UseUserCoreData";

const shuffleArray = (arr: CoreAmplified[]): CoreAmplified[] => {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }

    return arr;
};

const getIndexOfUserData = (arr: CoreAmplified[], ownerId: string): number => {
    let idx = 0;
    arr.every((core, index) => {
        if (core.ownerId === ownerId) {
            idx = index;

            return false;
        }

        return true;
    });

    return idx;
};

export default function useAllAmplifiedCoreData(): {
    status: "success" | "loading";
    data: CoreAmplified[];
    userData: CoreAmplified | null;
} {
    const [isProcessed, setIsProcessed] = useState(false);
    const { status: userCoreStatus, data: userCoreData } = useUserCoreData();
    const { status: allCoreStatus, data: allCoreData } = useAllCoreData();
    const [amplifiedUserCoreData, setAmplifiedUserCoreData] =
        useState<CoreAmplified | null>(null);

    const [amplifiedCoreData, setAmplifiedCoreData] = useState<CoreAmplified[]>(
        []
    );

    const status =
        userCoreStatus !== "success" ||
            (allCoreStatus !== "success" && !isProcessed)
            ? "loading"
            : "success";

    useEffect(() => {
        if (status === "success") {
            const amplifiedCoreDataSet: CoreAmplified[] = [];
            const sortedBookOfBusiness = sortCoreByKey({
                allCore: allCoreData,
                key: "bookOfBusiness",
            });
            const sortedBillableHours = sortCoreByKey({
                allCore: allCoreData,
                key: "billableHours",
            });
            const sortedHourlyRate = sortCoreByKey({
                allCore: allCoreData,
                key: "hourlyRate",
            });
            const sortedCollectedWorkingBillables = sortCoreByKey({
                allCore: allCoreData,
                key: "collectedWorkingBillables",
            });
            const sortedSalary = sortCoreByKey({
                allCore: allCoreData,
                key: "salary",
            });

            const sortedBonus = sortCoreByKey({ allCore: allCoreData, key: "bonus" });
            const sortedTotalCompensation = allCoreData.sort(
                (a, b) => b.salary + b.bonus - (a.salary + a.bonus)
            );
            const sortedGrossWorkingBillables = allCoreData.sort(
                (a, b) =>
                    b.billableHours + b.hourlyRate - (a.billableHours + a.hourlyRate)
            );

            allCoreData.forEach((coreData: Core) => {
                const bookOfBusinessRank = getRankFromSortedCore({
                    sortedCore: sortedBookOfBusiness,
                    currentCore: coreData,
                });
                const billableHoursRank = getRankFromSortedCore({
                    sortedCore: sortedBillableHours,
                    currentCore: coreData,
                });
                const hourlyRateRank = getRankFromSortedCore({
                    sortedCore: sortedHourlyRate,
                    currentCore: coreData,
                });
                const collectedWorkingBillablesRank = getRankFromSortedCore({
                    sortedCore: sortedCollectedWorkingBillables,
                    currentCore: coreData,
                });
                const salaryRank = getRankFromSortedCore({
                    sortedCore: sortedSalary,
                    currentCore: coreData,
                });
                const bonusRank = getRankFromSortedCore({
                    sortedCore: sortedBonus,
                    currentCore: coreData,
                });
                const totalCompensationRank = getRankFromSortedCore({
                    sortedCore: sortedTotalCompensation,
                    currentCore: coreData,
                });
                const grossWorkingBillablesRank = getRankFromSortedCore({
                    sortedCore: sortedGrossWorkingBillables,
                    currentCore: coreData,
                });
                const {
                    market,
                    areaOfLaw,
                    experience,
                    role,
                    membershipId,
                    verified,
                    claimUIC,
                    id,
                    ownerId,
                    billableHours,
                    hourlyRate,
                    collectedWorkingBillables,
                    salary,
                    bonus,
                    bookOfBusiness,
                } = coreData;

                const amplifiedCoreElement: CoreAmplified = {
                    market,
                    areaOfLaw,
                    experience,
                    role,
                    membershipId,
                    verified,
                    claimUIC,
                    id,
                    ownerId,
                    billableHours: {
                        value: billableHours,
                        diff: userCoreData.billableHours - billableHours,
                        rank: billableHoursRank,
                    },
                    hourlyRate: {
                        value: hourlyRate,
                        diff: userCoreData.hourlyRate - hourlyRate,
                        rank: hourlyRateRank,
                    },
                    grossWorkingBillables: {
                        value: billableHours * hourlyRate,
                        diff:
                            userCoreData.billableHours * userCoreData.hourlyRate -
                            billableHours * hourlyRate,
                        rank: grossWorkingBillablesRank,
                    },
                    collectedWorkingBillables: {
                        value: collectedWorkingBillables,
                        diff:
                            userCoreData.collectedWorkingBillables -
                            collectedWorkingBillables,
                        rank: collectedWorkingBillablesRank,
                    },
                    salary: {
                        value: salary,
                        diff: userCoreData.salary - salary,
                        rank: salaryRank,
                    },
                    bonus: {
                        value: bonus,
                        diff: userCoreData.bonus - bonus,
                        rank: bonusRank,
                    },
                    bookOfBusiness: {
                        value: bookOfBusiness,
                        diff: userCoreData.bookOfBusiness - bookOfBusiness,
                        rank: bookOfBusinessRank,
                    },
                    totalCompensation: {
                        value: salary + bonus,
                        diff: userCoreData.salary + userCoreData.bonus - (salary + bonus),
                        rank: totalCompensationRank,
                    },
                };

                amplifiedCoreDataSet.push(amplifiedCoreElement);
            });

            setAmplifiedUserCoreData(
                amplifiedCoreDataSet.find(
                    (core) => core.ownerId === userCoreData.ownerId
                ) as unknown as CoreAmplified
            );
            amplifiedCoreDataSet.splice(
                getIndexOfUserData(amplifiedCoreDataSet, userCoreData.ownerId),
                1
            );
            setAmplifiedCoreData(shuffleArray(amplifiedCoreDataSet));
            setIsProcessed(true);
        }
    }, [allCoreData, status]);

    return {
        status,
        data: amplifiedCoreData,
        userData: amplifiedUserCoreData,
    };
}
