export const MARKETS = [
    { label: "New York, NY", value: "New York, NY" },
    { label: "San Francisco, CA", value: "San Francisco, CA" },
    { label: "Washington, DC", value: "Washington, DC" },
    { label: "Miami, FL", value: "Miami, FL" },
    { label: "Boston, MA", value: "Boston, MA" },
    { label: "Los Angeles, CA", value: "Los Angeles, CA" },
    { label: "Wilmington, DE", value: "Wilmington, DE" },
    { label: "Newark, NJ", value: "Newark, NJ" },
    { label: "San Jose, CA", value: "San Jose, CA" },
    { label: "Bridgeport, CT", value: "Bridgeport, CT" },
    { label: "Philadelphia, PA", value: "Philadelphia, PA" },
    { label: "Denver, CO", value: "Denver, CO" },
    { label: "Bethesda, MD", value: "Bethesda, MD" },
    { label: "Houston, TX", value: "Houston, TX" },
    { label: "Atlanta, GA", value: "Atlanta, GA" },
    { label: "Austin, TX", value: "Austin, TX" },
    { label: "Hartford, CT", value: "Hartford, CT" },
    { label: "Dallas, TX", value: "Dallas, TX" },
    { label: "New Orleans, LA", value: "New Orleans, LA" },
    { label: "Orlando, FL", value: "Orlando, FL" },
    { label: "San Diego, CA", value: "San Diego, CA" },
    { label: "Richmond, VA", value: "Richmond, VA" },
    { label: "Tulsa, OK", value: "Tulsa, OK" },
    { label: "Sacramento, CA", value: "Sacramento, CA" },
    { label: "Trenton, NJ", value: "Trenton, NJ" },
    { label: "Chicago, IL", value: "Chicago, IL" },
    { label: "Warren, MI", value: "Warren, MI" },
    { label: "Santa Ana, CA", value: "Santa Ana, CA" },
    { label: "Minneapolis, MN", value: "Minneapolis, MN" },
    { label: "Pittsburgh, PA", value: "Pittsburgh, PA" },
    { label: "Seattle, WA", value: "Seattle, WA" },
    { label: "Camden, NJ", value: "Camden, NJ" },
    { label: "North Port, FL", value: "North Port, FL" },
    { label: "Kansas City, MO", value: "Kansas City, MO" },
    { label: "Salt Lake City, UT", value: "Salt Lake City, UT" },
    { label: "Edison, NJ", value: "Edison, NJ" },
    { label: "West Palm Beach, FL", value: "West Palm Beach, FL" },
    { label: "Nassau, NY", value: "Nassau, NY" },
    { label: "Raleigh, NC", value: "Raleigh, NC" },
    { label: "Las Vegas, NV", value: "Las Vegas, NV" },
    { label: "Oakland, CA", value: "Oakland, CA" },
    { label: "Oxnard, CA", value: "Oxnard, CA" },
    { label: "Harrisburg, PA", value: "Harrisburg, PA" },
    { label: "Tampa, FL", value: "Tampa, FL" },
    { label: "Framingham, MA", value: "Framingham, MA" },
    { label: "Des Moines, IA", value: "Des Moines, IA" },
    { label: "Manchester, NH", value: "Manchester, NH" },
    { label: "Charlotte, NC", value: "Charlotte, NC" },
    { label: "Albany, NY", value: "Albany, NY" },
    { label: "Phoenix, AZ", value: "Phoenix, AZ" },
    { label: "St. Louis, MO", value: "St. Louis, MO" },
    { label: "Salem, OR", value: "Salem, OR" },
    { label: "Lake County, IL", value: "Lake County, IL" },
    { label: "Portland, OR", value: "Portland, OR" },
    { label: "Baltimore, MD", value: "Baltimore, MD" },
    { label: "Fort Lauderdale, FL", value: "Fort Lauderdale, FL" },
    { label: "Tallahassee, FL", value: "Tallahassee, FL" },
    { label: "Boulder, CO", value: "Boulder, CO" },
    { label: "El Paso, TX", value: "El Paso, TX" },
    { label: "Naples, FL", value: "Naples, FL" },
    { label: "Santa Fe, NM", value: "Santa Fe, NM" },
    { label: "Portland, ME", value: "Portland, ME" },
    { label: "Ann Arbor, MI", value: "Ann Arbor, MI" },
    { label: "Birmingham, AL", value: "Birmingham, AL" },
    { label: "Springfield, IL", value: "Springfield, IL" },
    { label: "Great Falls, MT", value: "Great Falls, MT" },
    { label: "New Haven, CT", value: "New Haven, CT" },
    { label: "Wheeling, WV", value: "Wheeling, WV" },
    { label: "Cincinnati, OH", value: "Cincinnati, OH" },
    { label: "San Antonio, TX", value: "San Antonio, TX" },
    { label: "Cleveland, OH", value: "Cleveland, OH" },
    { label: "Pittsfield, MA", value: "Pittsfield, MA" },
    { label: "Oklahoma City, OK", value: "Oklahoma City, OK" },
    { label: "Dover, DE", value: "Dover, DE" },
    { label: "Cheyenne, WY", value: "Cheyenne, WY" },
    { label: "Jackson, MS", value: "Jackson, MS" },
    { label: "Charleston, WV", value: "Charleston, WV" },
    { label: "Mobile, AL", value: "Mobile, AL" },
    { label: "Santa Barbara, CA", value: "Santa Barbara, CA" },
    { label: "Columbus, OH", value: "Columbus, OH" },
    { label: "Anchorage, AK", value: "Anchorage, AK" },
    { label: "Fresno, CA", value: "Fresno, CA" },
    { label: "Dayton, OH", value: "Dayton, OH" },
    { label: "Corpus Christi, TX", value: "Corpus Christi, TX" },
    { label: "Fayetteville, AR", value: "Fayetteville, AR" },
    { label: "Nashville, TN", value: "Nashville, TN" },
    { label: "Lubbock, TX", value: "Lubbock, TX" },
    { label: "Charlottesville, VA", value: "Charlottesville, VA" },
    { label: "Reading, PA", value: "Reading, PA" },
    { label: "Jacksonville, FL", value: "Jacksonville, FL" },
    { label: "Santa Rosa, CA", value: "Santa Rosa, CA" },
    { label: "Atlantic City, NJ", value: "Atlantic City, NJ" },
    { label: "Knoxville, TN", value: "Knoxville, TN" },
    { label: "Valdosta, GA", value: "Valdosta, GA" },
    { label: "Virginia Beach, VA", value: "Virginia Beach, VA" },
    { label: "Boise City, ID", value: "Boise City, ID" },
    { label: "Providence, RI", value: "Providence, RI" },
    { label: "Milwaukee, WI", value: "Milwaukee, WI" },
    { label: "Montgomery, AL", value: "Montgomery, AL" },
    { label: "Indianapolis, IN", value: "Indianapolis, IN" },
];

export const AREAS_OF_LAW = [
    { label: "Select Practice Area", value: "Select Practice Area" },
    {
        label: "Administrative / Regulatory Law",
        value: "Administrative / Regulatory Law",
    },
    { label: "Admiralty & Maritime Law", value: "Admiralty & Maritime Law" },
    { label: "Advertising Law", value: "Advertising Law" },
    { label: "Antitrust Law", value: "Antitrust Law" },
    { label: "Appellate Practice", value: "Appellate Practice" },
    { label: "Arbitration", value: "Arbitration" },
    { label: "Aviation Law", value: "Aviation Law" },
    { label: "Banking and Finance Law", value: "Banking and Finance Law" },
    {
        label:
            "Bankruptcy and Creditor Debtor Rights / Insolvency and Reorganization Law",
        value:
            "Bankruptcy and Creditor Debtor Rights / Insolvency and Reorganization Law",
    },
    { label: "Bet-the-Company Litigation", value: "Bet-the-Company Litigation" },
    { label: "Biotechnology Law", value: "Biotechnology Law" },
    {
        label: "Business Organizations (including LLCs and Partnerships)",
        value: "Business Organizations (including LLCs and Partnerships)",
    },
    { label: "Civil Rights Law", value: "Civil Rights Law" },
    {
        label: "Closely Held Companies and Family Businesses Law",
        value: "Closely Held Companies and Family Businesses Law",
    },
    { label: "Commercial Finance Law", value: "Commercial Finance Law" },
    { label: "Commercial Litigation", value: "Commercial Litigation" },
    {
        label: "Commercial Transactions / UCC Law",
        value: "Commercial Transactions / UCC Law",
    },
    { label: "Communications Law", value: "Communications Law" },
    { label: "Construction Law", value: "Construction Law" },
    { label: "Copyright Law", value: "Copyright Law" },
    { label: "Corporate Compliance Law", value: "Corporate Compliance Law" },
    { label: "Corporate Governance Law", value: "Corporate Governance Law" },
    { label: "Corporate Law", value: "Corporate Law" },
    {
        label: "Criminal Defense: General Practice",
        value: "Criminal Defense: General Practice",
    },
    {
        label: "Criminal Defense: White-Collar",
        value: "Criminal Defense: White-Collar",
    },
    {
        label: "Derivatives and Futures Law",
        value: "Derivatives and Futures Law",
    },
    { label: "DUI/DWI Defense", value: "DUI/DWI Defense" },
    { label: "Economic Development Law", value: "Economic Development Law" },
    { label: "Education Law", value: "Education Law" },
    { label: "Elder Law", value: "Elder Law" },
    {
        label: "Eminent Domain and Condemnation Law",
        value: "Eminent Domain and Condemnation Law",
    },
    {
        label: "Employee Benefits (ERISA) Law",
        value: "Employee Benefits (ERISA) Law",
    },
    {
        label: "Employment Law - Individuals",
        value: "Employment Law - Individuals",
    },
    {
        label: "Employment Law - Management",
        value: "Employment Law - Management",
    },
    { label: "Energy Law", value: "Energy Law" },
    { label: "Energy Regulatory Law", value: "Energy Regulatory Law" },
    {
        label: "Entertainment Law - Motion Pictures & Television",
        value: "Entertainment Law - Motion Pictures & Television",
    },
    { label: "Entertainment Law - Music", value: "Entertainment Law - Music" },
    { label: "Environmental Law", value: "Environmental Law" },
    { label: "Equipment Finance Law", value: "Equipment Finance Law" },
    {
        label: "Ethics and Professional Responsibility Law",
        value: "Ethics and Professional Responsibility Law",
    },
    { label: "Family Law", value: "Family Law" },
    { label: "Family Law Mediation", value: "Family Law Mediation" },
    { label: "FDA Law", value: "FDA Law" },
    {
        label: "Financial Services Regulation Law",
        value: "Financial Services Regulation Law",
    },
    { label: "First Amendment Law", value: "First Amendment Law" },
    { label: "Franchise Law", value: "Franchise Law" },
    { label: "Gaming Law", value: "Gaming Law" },
    {
        label: "Government Relations Practice",
        value: "Government Relations Practice",
    },
    { label: "Health Care Law", value: "Health Care Law" },
    { label: "Immigration Law", value: "Immigration Law" },
    { label: "Information Technology Law", value: "Information Technology Law" },
    { label: "Insurance Law", value: "Insurance Law" },
    {
        label: "International Arbitration - Commercial",
        value: "International Arbitration - Commercial",
    },
    {
        label: "International Arbitration - Governmental",
        value: "International Arbitration - Governmental",
    },
    {
        label: "International Mergers & Acquisitions",
        value: "International Mergers & Acquisitions",
    },
    {
        label: "International Trade and Finance Law",
        value: "International Trade and Finance Law",
    },
    { label: "Labor Law - Management", value: "Labor Law - Management" },
    { label: "Labor Law - Union", value: "Labor Law - Union" },
    { label: "Land Use & Zoning Law", value: "Land Use & Zoning Law" },
    {
        label: "Legal Malpractice Law - Defendants",
        value: "Legal Malpractice Law - Defendants",
    },
    {
        label: "Legal Malpractice Law - Plaintiffs",
        value: "Legal Malpractice Law - Plaintiffs",
    },
    {
        label: "Leisure and Hospitality Law",
        value: "Leisure and Hospitality Law",
    },
    {
        label: "Leveraged Buyouts and Private Equity Law",
        value: "Leveraged Buyouts and Private Equity Law",
    },
    { label: "Litigation - Antitrust", value: "Litigation - Antitrust" },
    {
        label: "Litigation - Banking & Finance",
        value: "Litigation - Banking & Finance",
    },
    { label: "Litigation - Bankruptcy", value: "Litigation - Bankruptcy" },
    { label: "Litigation - Construction", value: "Litigation - Construction" },
    { label: "Litigation - Environmental", value: "Litigation - Environmental" },
    { label: "Litigation - ERISA", value: "Litigation - ERISA" },
    {
        label: "Litigation - First Amendment",
        value: "Litigation - First Amendment",
    },
    { label: "Litigation - Health Care", value: "Litigation - Health Care" },
    { label: "Litigation - Insurance", value: "Litigation - Insurance" },
    {
        label: "Litigation - Intellectual Property",
        value: "Litigation - Intellectual Property",
    },
    {
        label: "Litigation - Labor & Employment",
        value: "Litigation - Labor & Employment",
    },
    {
        label: "Litigation - Land Use & Zoning",
        value: "Litigation - Land Use & Zoning",
    },
    {
        label: "Litigation - Mergers & Acquisitions",
        value: "Litigation - Mergers & Acquisitions",
    },
    { label: "Litigation - Municipal", value: "Litigation - Municipal" },
    { label: "Litigation - Patent", value: "Litigation - Patent" },
    { label: "Litigation - Real Estate", value: "Litigation - Real Estate" },
    {
        label: "Litigation - Regulatory Enforcement (SEC, Telecom, Energy)",
        value: "Litigation - Regulatory Enforcement (SEC, Telecom, Energy)",
    },
    { label: "Litigation - Securities", value: "Litigation - Securities" },
    { label: "Litigation - Tax", value: "Litigation - Tax" },
    {
        label: "Litigation - Trusts & Estates",
        value: "Litigation - Trusts & Estates",
    },
    {
        label: "Mass Tort Litigation / Class Actions - Defendants",
        value: "Mass Tort Litigation / Class Actions - Defendants",
    },
    {
        label: "Mass Tort Litigation / Class Actions - Plaintiffs",
        value: "Mass Tort Litigation / Class Actions - Plaintiffs",
    },
    { label: "Media Law", value: "Media Law" },
    { label: "Mediation", value: "Mediation" },
    {
        label: "Medical Malpractice Law - Defendants",
        value: "Medical Malpractice Law - Defendants",
    },
    {
        label: "Medical Malpractice Law - Plaintiffs",
        value: "Medical Malpractice Law - Plaintiffs",
    },
    { label: "Mergers & Acquisitions Law", value: "Mergers & Acquisitions Law" },
    { label: "Mining Law", value: "Mining Law" },
    {
        label: "Mortgage Banking Foreclosure Law",
        value: "Mortgage Banking Foreclosure Law",
    },
    { label: "Municipal Law", value: "Municipal Law" },
    { label: "Mutual Funds Law", value: "Mutual Funds Law" },
    { label: "Native American Law", value: "Native American Law" },
    { label: "Natural Resources Law", value: "Natural Resources Law" },
    { label: "Nonprofit / Charities Law", value: "Nonprofit / Charities Law" },
    { label: "Oil & Gas Law", value: "Oil & Gas Law" },
    { label: "Patent Law", value: "Patent Law" },
    {
        label: "Personal Injury Litigation - Defendants",
        value: "Personal Injury Litigation - Defendants",
    },
    {
        label: "Personal Injury Litigation - Plaintiffs",
        value: "Personal Injury Litigation - Plaintiffs",
    },
    {
        label: "Privacy and Data Security Law",
        value: "Privacy and Data Security Law",
    },
    {
        label: "Private Funds / Hedge Funds Law",
        value: "Private Funds / Hedge Funds Law",
    },
    {
        label: "Product Liability Litigation - Defendants",
        value: "Product Liability Litigation - Defendants",
    },
    {
        label: "Product Liability Litigation - Plaintiffs",
        value: "Product Liability Litigation - Plaintiffs",
    },
    {
        label: "Professional Malpractice Law - Defendants",
        value: "Professional Malpractice Law - Defendants",
    },
    {
        label: "Professional Malpractice Law - Plaintiffs",
        value: "Professional Malpractice Law - Plaintiffs",
    },
    { label: "Project Finance Law", value: "Project Finance Law" },
    { label: "Public Finance Law", value: "Public Finance Law" },
    { label: "Railroad Law", value: "Railroad Law" },
    { label: "Real Estate Law", value: "Real Estate Law" },
    {
        label: "Securities / Capital Markets Law",
        value: "Securities / Capital Markets Law",
    },
    { label: "Securities Regulation", value: "Securities Regulation" },
    {
        label: "Securitization and Structured Finance Law",
        value: "Securitization and Structured Finance Law",
    },
    { label: "Sports Law", value: "Sports Law" },
    { label: "Tax Law ", value: "Tax Law " },
    { label: "Technology Law", value: "Technology Law" },
    { label: "Timber Law", value: "Timber Law" },
    { label: "Trademark Law", value: "Trademark Law" },
    { label: "Transportation Law", value: "Transportation Law" },
    { label: "Trusts & Estates Law", value: "Trusts & Estates Law" },
    { label: "Utilities Law", value: "Utilities Law" },
    { label: "Venture Capital Law ", value: "Venture Capital Law " },
    { label: "Water Law", value: "Water Law" },
    {
        label: "Workers' Compensation Law - Claimants",
        value: "Workers' Compensation Law - Claimants",
    },
    {
        label: "Workers' Compensation Law - Employers",
        value: "Workers' Compensation Law - Employers",
    },
];

export const YEARS_OF_EXPERIENCE = [
    { label: "1-3", value: "1-3" },
    { label: "4-6", value: "4-6" },
    { label: "7-10", value: "7-10" },
    { label: "11-15", value: "11-15" },
    { label: "16+", value: "16+" },
];

export const INTERESTED_IN_RECRUITERS = [
    { label: "Yes.", value: "yes" },
    { label: "No.", value: "no" },
];
