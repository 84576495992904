export enum VerificationRequestStatus {
    VERIFIED,
    PENDING,
    INVALID
}

export type VerificationRequest = {
    id: string;
    requester: string;
    verifier: string;
    requesterPhone: string;
    invitersClaim: string;
    verifiersClaim: string;
    status: VerificationRequestStatus;
}