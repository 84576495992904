export type Chat = {
    id: string;
    body: string;
    senderId: string;
    receiverId: string;
    senderMembershipId: string;
    conversationId: string;
    receiverMembershipId: string;
    type: MessageType;
    timestamp: number;
    seen: boolean;
}

export enum MessageType {
    SENTINEL = 'sentinel',
    MESSAGE = "message"
}

export type Conversation = {
    id: string;
    membershipId: string;
    conversationId: string;
    messages: Chat[];
    otherMemberId: string;
    hasUnseen: boolean;
}